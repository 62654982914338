const LABEL_BICYCLES_TABLE = {
  columns: [
    {
      label: 'bicycles.system_name_header',
      field: 'system.name',
      ordering: true,
      getValue(raw) {
        return raw.system ? raw.system.name : '';
      }
    },
    {
      label: 'bicycles.number_header',
      field: 'number',
      ordering: true
    },
    {
      label: 'bicycles.model_header',
      field: 'model',
      ordering: true
    },
    {
      label: 'bicycles.status_header',
      field: 'status',
      ordering: true,
      translate: true,
      getValue(raw) {
        return 'bike_status.' + raw.status;
      }
    },
    {
      label: 'bicycles.location_header',
      field: 'location.label',
      getValue: (raw) => {
        return raw.location.label
      },
      ordering: true
    },
    {
      label: 'bicycles.serial_number_header',
      field: 'serialNumber',
      ordering: true
    },
    {
      label: 'bicycles.lock_header',
      field: 'lockMacAddress',
      ordering: true
    },
    {
      label: 'bicycles.power_level_header',
      field: 'powerLevel',
      ordering: true,
      getValue(raw) {
        return raw.powerLevel ? (raw.powerLevel + "%") : 'None';
      },
      getStyle(raw) {
        return (raw.powerLevel && raw.powerLevel < 50) ? {color: '#f44336'} : {};
      }
      /*translate: true,  TODO need to revert
      getValue(raw) {
        if (!raw.powerLevel) {
          return 'power_level.none';
        } else if (raw.powerLevel >= 80) {
          return 'power_level.good';
        } else if (raw.powerLevel >= 50) {
          return 'power_level.low';
        } else if (raw.powerLevel < 50) {
          return 'power_level.bad';
        }

        return 'power_level.none';
      },
      getStyle(raw) {
        if (!raw.powerLevel) {
          return {textAlign: 'center', width: '48px', display: 'block'};
        } else if (raw.powerLevel >= 80) {
          return {color: '#4CAF50', textAlign: 'center', width: '48px', display: 'block'};
        } else if (raw.powerLevel >= 50) {
          return {color: '#FF9800', textAlign: 'center', width: '48px', display: 'block'};
        } else if (raw.powerLevel < 50) {
          return {color: '#f44336', textAlign: 'center', width: '48px', display: 'block'};
        }

        return {textAlign: 'center', width: '48px', display: 'block'};
      }*/
    },
    {
      label: 'bicycles.last_scan_header',
      field: 'lastScan',
      ordering: true,
      getValue(raw) {
        return raw.lastScan ? raw.lastScan : '-'
      }
    }
  ],
  footerLabel: 'bicycles.footer_label'
}

export default LABEL_BICYCLES_TABLE;
