import React from 'react';
import {connect} from "react-redux";
import {Trans} from "react-i18next";
import Button from "@material-ui/core/Button";
import {MANAGER} from "core/utils/permissions";
import {exportBicyclesToCsv, loadBicycles} from "core/actions/bicycle-actions";
import PagePermission from "components/permission/PagePermission";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsPanelHeaderFilters from "components/panel/header/BsPanelHeaderFilters";
import BsDataTable from "components/data-table/BsDataTable";
import BsPanel from "components/panel/BsPanel";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import BicyclesFilters from "./BicyclesFilters";
import BICYCLES_TABLE from "./bicycles-table";
import PlusIcon from "assets/img/plus.svg";
import Battery from "assets/img/battery_mid.png";
import BatteryFull from "assets/img/battery_full.png";
import BatteryLow from "assets/img/battery_low.png";

const queryString = require('query-string');

class Bicycles extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction,
      fields: {
        number: query.number != null ? query.number : '',
        address: query.address != null ? query.address : '',
        serialNumber: query.serialNumber != null ? query.serialNumber : '',
        status: query.status != null ? (typeof query.status === "string" ? [query.status] : query.status) : []
      }
    };

    this.config = {
      ...BICYCLES_TABLE
    }
    for (let i = 0; i < this.config.columns.length; i++) {
      if (this.config.columns[i].field === 'powerLevel') {
        this.config.columns[i].getValue = (raw) => {
          if (!raw.powerLevel) {
            return 'none';
          } else if (raw.powerLevel >= 80) {
            return <img src={BatteryFull} alt={raw.powerLevel} className="battery-icon"/>
          } else if (raw.powerLevel >= 50) {
            return <img src={Battery} alt={raw.powerLevel} className="battery-icon"/>
          } else if (raw.powerLevel < 50) {
            return <img src={BatteryLow} alt={raw.powerLevel} className="battery-icon"/>
          }

          return 'none';
        };
      }
    }

    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
  }

  onHandleFilter = () => {
    const params = queryString.parse(this.props.location.search);
    params.number = this.state.fields.number;
    params.address = this.state.fields.address;
    params.serialNumber = this.state.fields.serialNumber;
    params.status = this.state.fields.status;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
  }

  onHandleClear = () => {
    const params = queryString.parse(this.props.location.search);
    delete params.number;
    delete params.address;
    delete params.serialNumber;
    delete params.status;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
    this.setState({
      ...this.state,
      fields: {
        number: '',
        address: '',
        serialNumber: '',
        status: []
      }
    })
  }

  onLoadData = (query) => {
    this.props.loadBicycles(this.props.match.params.systemId, this.prepareQuery(query));
  }

  addBicycle = () => {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/create-bicycle')
  }

  onRowClick = (row) => {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/bicycle/' + row.id)
  }

  export = () => {
    const query = queryString.parse(this.props.location.search);
    this.props.exportBicyclesToCsv(this.props.match.params.systemId, this.prepareQuery(query));
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }
    if (query.address) {
      query['location.label'] = query.address;
      delete query.address;
    }
    if (query.status) {
      query.status = query.status instanceof Array ? query.status.join(',') : query.status
    }

    return query
  }

  render() {
    let addButton = undefined;
    if (this.props.isSuperAdmin) {
      addButton = <Button variant="contained" color="primary" type="submit" onClick={this.addBicycle}>
        <PlusIcon/>&nbsp;<Trans i18nKey="general.add"/>
      </Button>;
    }

    return (
        <BsPanel padding={true}>
          <PagePermission anonymous={false} permission={MANAGER}/>
          <BsPanelLoader show={this.props.loading}/>
          <BsPanelHeader>
            <BsPanelTitle action={
              <>
                {addButton}
                <Button variant="outlined" type="button" onClick={this.export}>
                  <Trans i18nKey="general.export"/>
                </Button>
              </>
            }>
              <Trans i18nKey="bicycles.title"/>
            </BsPanelTitle>
          </BsPanelHeader>

          <BsPanelHeaderFilters onHandleFilter={this.onHandleFilter.bind(this)}
                                onHandleClear={this.onHandleClear.bind(this)}>
            <BicyclesFilters fields={this.state.fields}
                             onHandleChange={this.handleChange.bind(this)}/>
          </BsPanelHeaderFilters>

          <BsDataTable location={this.props.location}
                       history={this.props.history}
                       order={this.state.direction}
                       orderBy={this.state.sort}
                       settings={this.config}
                       content={this.props.bicycles}
                       onRowClick={this.onRowClick}
                       onChangePage={this.onLoadData}
                       onChangeSize={this.onLoadData}
                       onRequestSort={this.onRequestSort}/>

        </BsPanel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    bicycles: state.bicycle.bicycles,
    loading: state.bicycle.loading,
    isSuperAdmin: state.profile.profile != null && state.profile.profile.role != null
      && state.profile.profile.role.indexOf('SUPER_ADMIN') > -1
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadBicycles: (systemId, query) => {
      dispatch(loadBicycles(systemId, query));
    },
    exportBicyclesToCsv: (systemId, query) => {
      dispatch(exportBicyclesToCsv(systemId, query));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bicycles);
