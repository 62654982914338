import React, {useEffect} from 'react';
import {Route} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import BsPanel from "components/panel/BsPanel";
import PagePermission from "components/permission/PagePermission";
import {ADMIN} from "core/utils/permissions";
import {fetchRentalRateCoupon} from "core/actions/rental-rate-coupon-actions";
import RentalRateCouponInfoTab from "./tabs/info/RentalRateCouponInfoTab";
import RentalCouponPaymentsTab from "./tabs/payments/RentalCouponPaymentsTab";

function RentalRateCouponInfo(props) {
  const lastSegment = props.location.pathname.replace(props.match.url, '').replace('/', '')
  const defaultTab = lastSegment.split('?')[0]
  const [value, setValue] = React.useState(defaultTab ? defaultTab : 'info');
  const {t} = useTranslation();

  useEffect(() => {
    if (!props.loadingCurrentRate) {
      props.fetchRentalRateCoupon(props.match.params.systemId, props.match.params.couponId);
    }
  }, []);

  const handleChange = (event, newValue) => {
    if (value !== newValue) {
      props.history.push((props.match.url + '/' + newValue).replace('//', '/'))
    }
    setValue(newValue);
  };

  let tabs = null;
  if (props.profile && props.system && props.system.role && props.currentCoupon) {
    tabs = (
        <Tabs value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary">
          <Tab label={t('rental_rate_coupon_info.info_tab')} value="info"/>
          <Tab label={t('rental_rate_coupon_info.payments_tab')} value="payments"/>
        </Tabs>
    )
  }

  return (
      <>
        <PagePermission anonymous={false} permission={ADMIN}/>
        <BsPanel>
          {tabs}
          <Route exact path="/system/:systemId/rental-rate-coupon/:couponId" component={RentalRateCouponInfoTab}/>
          <Route path="/system/:systemId/rental-rate-coupon/:couponId/info" component={RentalRateCouponInfoTab}/>
          <Route path="/system/:systemId/rental-rate-coupon/:couponId/payments" component={RentalCouponPaymentsTab}/>
        </BsPanel>
      </>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    system: state.system.currentSystem,
    currentCoupon: state.rentalRateCoupon.currentCoupon
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRentalRateCoupon: (systemId, couponId) => {
      dispatch(fetchRentalRateCoupon(systemId, couponId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RentalRateCouponInfo);
