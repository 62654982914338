import React from "react";
import TextField from "@material-ui/core/TextField";
import BsPanelHeaderFilterControl from "components/panel/header/BsPanelHeaderFilterControl";
import Col from "components/layout/Col";
import Row from "components/layout/Row";
import {useTranslation} from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const AdminSettingsFilters = (props) => {
    const {t} = useTranslation();
    const nameRef = React.createRef();
    const emailRef = React.createRef();

    return (
        <Row>
            <Col size="4">
                <BsPanelHeaderFilterControl>
                    <TextField label={t('admins.name_filter')}
                               ref={nameRef}
                               onChange={props.onHandleChange.bind(this, "name")}
                               value={props.fields["name"]}
                               className="form-control"/>
                </BsPanelHeaderFilterControl>
            </Col>
            <Col size="4">
                <BsPanelHeaderFilterControl>
                    <TextField label={t('admins.email_filter')}
                               ref={emailRef}
                               onChange={props.onHandleChange.bind(this, "email")}
                               value={props.fields["email"]}
                               className="form-control"/>
                </BsPanelHeaderFilterControl>
            </Col>
            <Col size="4">
              <BsPanelHeaderFilterControl>
                <FormControl className="form-control">
                  <InputLabel id="status-label">{t('admins.status_filter')}</InputLabel>
                  <Select
                    labelId="status-label"
                    id="status"
                    value={props.fields["status"]}
                    onChange={props.onHandleChange.bind(this, "status")}>
                    <MenuItem value="">{t('admin_status.undefined')}</MenuItem>
                    <MenuItem value="ACTIVE">{t('admin_status.ACTIVE')}</MenuItem>
                    <MenuItem value="INACTIVE">{t('admin_status.INACTIVE')}</MenuItem>
                    <MenuItem value="PENDING_EMAIL">{t('admin_status.PENDING_EMAIL')}</MenuItem>
                  </Select>
                </FormControl>
              </BsPanelHeaderFilterControl>
            </Col>
        </Row>
    )
}

export default AdminSettingsFilters;
