import {priceToString} from "core/utils/priceUtils";

const buildRentalPaymentInfoForm = (payment, systemConfig) => {
  const form = {
    fields: [
      {
        name: 'client',
        label: 'rental_payment_info.client_field',
        type: 'info',
        getValue: (data) => {
          return data.client.firstName + ' ' + data.client.lastName
        }
      },
      {
        name: 'name',
        label: 'rental_payment_info.rate_name_field',
        type: 'info',
        getValue: (data) => {
          return data.name
        }
      }
      /*{
        name: 'refundPolicy',
        label: 'rental_payment_info.refund_policy_field',
        type: 'info',
        getValue: (data) => {
          let unit = data.refundPolicy.unit.toLowerCase();
          return data.refundPolicy.amount + ' ' + unit.charAt(0).toUpperCase() + unit.slice(1);
        }
      },*/
      /*{
        name: 'gracePeriod',
        label: 'rental_payment_info.grace_period_field',
        type: 'info',
        getValue: (data) => {
          let unit = data.gracePeriod.unit.toLowerCase();
          return data.gracePeriod.amount + ' ' + unit.charAt(0).toUpperCase() + unit.slice(1);
        }
      },*/
    ]
  };

  if (systemConfig.collectTax) {
    form.fields.push({
      name: 'price',
      label: 'rental_payment_info.rate_price_field',
      type: 'info',
      getValue: (data) => {
        return priceToString(data.price, data.currency)
      }
    });

    if (payment.couponId) {
      form.fields.push({
        name: 'discount',
        label: 'rental_payment_info.discount_amount_field',
        type: 'reference-link',
        getValue: (data) => {
          if (data.type === 'FIXED_AMOUNT') {
            return priceToString(data.discount, data.discountCurrency);
          } else {
            return data.discount + '%';
          }
        },
        link: (data) => {
          if (data.couponId) {
            return '/system/' + data.systemId + '/rental-rate-coupon/' + payment.couponId;
          } else {
            return "/";
          }
        }
      });
    }

    form.fields.push({
      name: 'taxAmount',
      label: 'rental_payment_info.rate_tax_amount_field',
      type: 'info',
      getValue: (data) => {
        return priceToString(data.taxAmount, data.taxCurrency)
      }
    });
    form.fields.push({
      name: 'totalAmount',
      label: 'rental_payment_info.rate_total_amount_field',
      type: payment.rentalId ? 'reference-link' : 'info',
      getValue: (data) => {
        return priceToString(data.totalAmount, data.totalAmountCurrency)
      },
      link: (data) => {
        if (data.rentalId) {
          return '/system/' + data.systemId + '/rental/history/' + data.rentalId + '/info'
        } else {
          return "/"
        }
      }
    });
  } else {
    if (payment.couponId) {
      form.fields.push({
        name: 'discount',
        label: 'rental_payment_info.discount_amount_field',
        type: 'reference-link',
        getValue: (data) => {
          if (data.type === 'FIXED_AMOUNT') {
            return priceToString(data.discount, data.discountCurrency);
          } else {
            return data.discount + '%';
          }
        },
        link: (data) => {
          if (data.couponId) {
            return '/system/' + data.systemId + '/rental-rate-coupon/' + payment.couponId;
          } else {
            return "/";
          }
        }
      });
    }

    form.fields.push({
      name: 'price',
      label: 'rental_payment_info.rate_price_field',
      type: payment.rentalId ? 'reference-link' : 'info',
      getValue: (data) => {
        return priceToString(data.price, data.currency)
      },
      link: (data) => {
        if (data.rentalId) {
          return '/system/' + data.systemId + '/rental/history/' + data.rentalId + '/info'
        } else {
          return "/"
        }
      }
    });
  }

  form.fields.push({
    name: 'rentalDuration',
    label: 'rental_payment_info.rental_duration_field',
    type: 'info',
    getValue: (data) => {
      let unit = data.rentalDuration.unit.toLowerCase();
      return data.rentalDuration.amount + ' ' + unit.charAt(0).toUpperCase() + unit.slice(1);
    }
  });
  form.fields.push({
    name: 'lateFeeAmount',
    label: 'rental_payment_info.late_fee_amount_field',
    type: 'info',
    getValue: (data) => {
      return priceToString(data.lateFeeAmount, data.lateFeeCurrency)
    }
  });
  form.fields.push({
    name: 'type',
    label: 'rental_payment_info.rate_type_field',
    type: 'info',
    translate: true,
    getValue: (data) => {
      return 'rental_rate_type.' + data.type;
    }
  });
  form.fields.push({
    name: 'status',
    label: 'rental_payment_info.payment_status_field',
    type: 'info',
    translate: true,
    getValue: (data) => {
      return 'rental_payment_status.' + data.status;
    }
  });
  form.fields.push({
    name: 'paidAt',
    label: 'rental_payment_info.paid_at_field',
    type: 'info',
    getValue: (data) => {
      return data.paidAt;
    }
  });

  return form;
}

export default buildRentalPaymentInfoForm;
