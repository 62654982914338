import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const BsFormAnyField = (props) => {
  const {t} = useTranslation();

  let className = 'bs-form__info ';
  if (props.className) {
    className += props.className;
  }

  return (
      <div className={className}>
        <div className="bs-form__info-label">
          {t(props.label)}
        </div>
        <div className="bs-form__info-value" onClick={props.onClick}>
          {props.value}
        </div>
      </div>
  )
}

BsFormAnyField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.element
};

export default BsFormAnyField;
