import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import {ADMIN} from "core/utils/permissions";
import {fetchRentalRateCoupon, updateRentalRateCoupon} from "core/actions/rental-rate-coupon-actions";
import buildUpdateRentalRateCouponForm from "./edit-rental-rate-coupon-form";
import moment from "moment";

import "./edit-rental-rate-coupon.scss";

class EditRentalRateCoupon extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: {
        code: '',
        type: '',
        discount: '',
        currency: '',
        usages: '',
        validTo: ''
      }
    };
    this.backUrl = '/system/' + this.props.match.params.systemId + '/rental-rate-coupon/' + this.props.match.params.couponId;
    this.props.fetchRentalRateCoupon(this.props.match.params.systemId, this.props.match.params.couponId);
  }

  handleSubmit(fields, e) {
    this.props.updateRentalRateCoupon(this.props.match.params.systemId, this.props.match.params.couponId, {
      code: this.props.currentCoupon.status !== 'CREATED' ? this.props.currentCoupon.code : fields.code,
      type: fields.type,
      discount: fields.discount,
      currency: fields.type === 'FIXED_AMOUNT' ? fields.currency : undefined,
      usageType: fields.unlimited ? 'UNLIMITED' : 'LIMITED',
      usages: fields.unlimited ? undefined : fields.usages,
      validTo: fields.unlimited ? undefined : moment(new Date(fields.validTo)).format('YYYY-MM-DD')
    });
  }

  handleChange(fields) {
    this.setState({
      ...this.state,
      fields: fields
    });
  }

  render() {
    if (this.props.currentCoupon) {
      const data = {
        ...this.props.currentCoupon,
        unlimited: this.props.currentCoupon.usageType === 'UNLIMITED'
      }

      this.fields = data;

      const formConfig = buildUpdateRentalRateCouponForm(this.props.currentCoupon.status,
        this.state.fields.type ? this.state.fields.type : data.type,
        this.state.fields.unlimited !== undefined ? this.state.fields.unlimited : data.unlimited)

      return (
        <div className="add-rental-rate-coupon">
          <BsFormPanel>
            <PagePermission anonymous={false} permission={ADMIN}/>
            <BsForm
              config={formConfig}
              formData={data}
              onHandleSubmit={this.handleSubmit.bind(this)}
              onHandleChange={this.handleChange.bind(this)}
              cancelAction={
                <BsFormCancelAction history={this.props.history}
                                    location={this.props.location}
                                    url={this.backUrl}/>
              }/>
          </BsFormPanel>
        </div>
      );
    } else {
      return '';
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentCoupon: state.rentalRateCoupon.currentCoupon
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRentalRateCoupon: (systemId, couponId) => {
      dispatch(fetchRentalRateCoupon(systemId, couponId));
    },
    updateRentalRateCoupon: (systemId, couponId, data) => {
      dispatch(updateRentalRateCoupon(systemId, couponId, data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditRentalRateCoupon);
