const LABEL_GENERAL_BICYCLE_INFO_FORM = {
  fields: [
    {
      name: 'system.name',
      label: 'bicycle_info.system_name_field',
      type: 'info',
      styles: {
        maxWidth: '500px',
        marginTop: '2em'
      },
      getValue: (data) => {
        return data.system.name;
      },
    },
    {
      name: 'number',
      label: 'bicycle_info.number_field',
      type: 'info',
      styles: {
        maxWidth: '500px'
      },
      getValue: (data) => {
        return data.number + '';
      }
    },
    {
      name: 'model',
      label: 'bicycle_info.model_field',
      type: 'info',
      styles: {
        maxWidth: '500px'
      }
    },
    {
      name: 'serialNumber',
      label: 'bicycle_info.serial_number_field',
      type: 'info',
      styles: {
        maxWidth: '500px'
      }
    },
    {
      name: 'location',
      label: 'bicycle_info.location_field',
      type: 'info',
      getValue: (data) => {
        return data.location.label;
      },
      styles: {
        maxWidth: '500px'
      }
    },
    {
      name: 'lockMacAddress',
      label: 'bicycle_info.lock_mac_address_field',
      type: 'info',
      styles: {
        maxWidth: '500px'
      }
    },
    {
      name: 'ulockKey',
      label: 'bicycle_info.u_lock_key_field',
      type: 'info',
      getValue: (data) => {
        return data.ulockKey ? data.ulockKey : 'none'
      },
      styles: {
        maxWidth: '500px'
      }
    },
    {
      name: 'powerLevel',
      label: 'bicycle_info.power_level_field',
      type: 'info',
      getValue: (data) => {
        return data.powerLevel ? (data.powerLevel + '%') : 'none'
      },
      /*translate: true,  TODO need to revert
      getValue(data) {
        if (!data.powerLevel) {
          return 'power_level.none';
        } else if (data.powerLevel >= 80) {
          return 'power_level.good';
        } else if (data.powerLevel >= 50) {
          return 'power_level.low';
        } else if (data.powerLevel < 50) {
          return 'power_level.bad';
        }

        return 'power_level.none';
      },
      getStyle(data) {
        if (!data.powerLevel) {
          return {};
        } else if (data.powerLevel >= 80) {
          return {color: '#4CAF50'};
        } else if (data.powerLevel >= 50) {
          return {color: '#FF9800'};
        } else if (data.powerLevel < 50) {
          return {color: '#f44336'};
        }

        return {};
      },*/
      styles: {
        maxWidth: '500px'
      },
    },
    {
      name: 'lastScan',
      label: 'bicycle_info.last_scan_field',
      type: 'info',
      getValue: (data) => {
        return data.lastScan ? data.lastScan : '-'
      },
      styles: {
        maxWidth: '500px'
      }
    },
    {
      name: 'status',
      label: 'bicycle_info.status_field',
      type: 'info',
      translate: true,
      getValue: (data) => {
        return 'bike_status.' + data.status;
      },
      styles: {
        maxWidth: '500px'
      }
    }
  ]
}

export default LABEL_GENERAL_BICYCLE_INFO_FORM;
