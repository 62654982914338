export const rentalRateCouponReducer = (state = {
  rentalRateCoupons: {},
  currentCoupon: null,
  loading: false,
  loadingCurrentCoupon: false
}, action) => {
  switch (action.type) {
    case 'FETCH_RENTAL_RATE_COUPONS':
      return {
        ...state,
        loading: true
      }
    case 'FETCH_RENTAL_RATE_COUPONS_SUCCESS':
      return {
        ...state,
        loading: false,
        rentalRateCoupons: action.payload.data
      }
    case 'FETCH_RENTAL_RATE_COUPONS_FAIL':
      return {
        ...state,
        loading: false
      }
    case 'FETCH_RENTAL_RATE_COUPON':
      return {
        ...state,
        currentCoupon: null,
        loadingCurrentCoupon: true
      }
    case 'FETCH_RENTAL_RATE_COUPON_FAIL':
      return {
        ...state,
        currentCoupon: null,
        loadingCurrentCoupon: false
      }
    case 'FETCH_RENTAL_RATE_COUPON_SUCCESS':
    case 'UPDATE_RENTAL_RATE_COUPON_SUCCESS':
      return {
        ...state,
        currentCoupon: action.payload.data,
        loadingCurrentCoupon: false
      }
    case 'DELETE_RENTAL_RATE_COUPON_SUCCESS':
      return {
        ...state,
        currentCoupon: null,
        loadingCurrentCoupon: false
      }
    case 'CREATE_RENTAL_RATE':
    case 'UPDATE_RENTAL_RATE_COUPON':
    case 'DELETE_RENTAL_RATE_COUPON':
    case 'CREATE_RENTAL_RATE_SUCCESS':
    case 'CREATE_RENTAL_RATE_FAIL':
    case 'UPDATE_RENTAL_RATE_COUPON_FAIL':
    case 'DELETE_RENTAL_RATE_COUPON_FAIL':
    default:
      return state
  }
}
