import React from "react";
import {connect} from "react-redux";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import ConfirmationModel from "components/model/ConfirmationModel";
import RentalRateCouponInfoTabActions from "./RentalRateCouponInfoTabActions";
import {ADMIN} from "core/utils/permissions";
import {
  fetchRentalRateCoupon,
  updateRentalRateCouponStatus,
  deleteRentalRateCoupon
} from "core/actions/rental-rate-coupon-actions";
import RENTAL_RATE_COUPON_INFO_FORM from "./rental-rate-coupon-info-form";

class RentalRateCouponInfoTab extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      confirmationWindow: {
        open: false,
        message: '',
        event: () => {
        }
      },
      resolveProblemWindow: {
        open: false,
      }
    };
    this.backUrl = '/system/' + this.props.match.params.systemId + '/rental-rates/coupons';
    this.props.fetchRentalRateCoupon(this.props.match.params.systemId, this.props.match.params.couponId);
  }

  onHandleActivate() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'rental_rate_coupon_info.activate_coupon_message',
        event: this.activateCoupon
      }
    })
  }

  activateCoupon() {
    this.closeConfirmationModel();
    this.props.updateRentalRateCouponStatus(this.props.match.params.systemId, this.props.match.params.couponId, {status: 'ACTIVE'})
  }

  onHandleCancel() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'rental_rate_coupon_info.cancel_coupon_message',
        event: this.cancelCoupon
      }
    })
  }

  cancelCoupon() {
    this.closeConfirmationModel();
    this.props.updateRentalRateCouponStatus(this.props.match.params.systemId, this.props.match.params.couponId, {status: 'CANCELED'})
  }

  onHandleDelete() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: 'rental_rate_coupon_info.delete_coupon_message',
        event: this.deleteCoupon
      }
    })
  }

  deleteCoupon() {
    this.closeConfirmationModel();
    this.props.deleteRentalRateCoupon(this.props.match.params.systemId, this.props.match.params.couponId)
  }

  closeConfirmationModel() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        ...this.state.confirmationWindow,
        open: false
      }
    });
  }

  onHandleEdit() {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/update-rental-rate-coupon-info/' + this.props.match.params.couponId)
  }

  render() {
    if (this.props.currentCoupon) {
      const formConfig = {...RENTAL_RATE_COUPON_INFO_FORM};
      formConfig.title = {
        actions: <RentalRateCouponInfoTabActions
          coupon={this.props.currentCoupon}
          onHandleActivate={this.onHandleActivate.bind(this)}
          onHandleCancel={this.onHandleCancel.bind(this)}
          onHandleDelete={this.onHandleDelete.bind(this)}
          onHandleEdit={this.onHandleEdit.bind(this)}/>,
        back: {
          url: this.backUrl,
          history: this.props.history,
          location: this.props.location
        },
        text: 'rental_rate_coupon_info.title'
      }

      return (
        <div className="bs-form-container">
          <PagePermission anonymous={false} permission={ADMIN}/>
          <ConfirmationModel
            open={this.state.confirmationWindow.open}
            message={this.state.confirmationWindow.message}
            handleClose={this.closeConfirmationModel.bind(this)}
            handleApply={this.state.confirmationWindow.event.bind(this)}
          />

          <BsForm
            config={formConfig}
            formData={this.props.currentCoupon}
            disabled={true}/>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentCoupon: state.rentalRateCoupon.currentCoupon
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRentalRateCoupon: (systemId, couponId) => {
      dispatch(fetchRentalRateCoupon(systemId, couponId));
    },
    updateRentalRateCouponStatus: (systemId, couponId, data) => {
      dispatch(updateRentalRateCouponStatus(systemId, couponId, data));
    },
    deleteRentalRateCoupon: (systemId, rentalId) => {
      dispatch(deleteRentalRateCoupon(systemId, rentalId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RentalRateCouponInfoTab);
