import React from "react";
import {connect} from "react-redux";
import {ADMIN, MANAGER} from "core/utils/permissions";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import {changeBicycleStatus, clearPowerLevelHistory, fetchBicycle} from "core/actions/bicycle-actions";
import buildBicycleInfoForm from "./bicycle-info-form";
import BicycleInfoActions from "./BicycleInfoActions";
import ConfirmationModel from "components/model/ConfirmationModel";
import PowerLevelHistoryModel from "./PowerLevelHistoryModel";
import BatteryFull from "assets/img/battery_full.png";
import Battery from "assets/img/battery_mid.png";
import BatteryLow from "assets/img/battery_low.png";

import './BicycleInfoTab.scss';

class BicycleInfoTab extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      backUrl: '/system/' + this.props.match.params.systemId + '/bicycles',
      confirmationWindow: {
        open: false,
        message: ''
      },
      powerLevelHistoryWindow: {
        open: false,
        openConfirmation: false
      }
    };
    this.props.fetchBicycle(this.props.match.params.systemId, this.props.match.params.bicycleId);
  }

  onHandleChangeStatus(status) {
    let message = '';

    switch (status) {
      case 'ACTIVE':
        message = 'bicycle_info.active_bicycle_confirmation_message';
        break;
      case 'INACTIVE':
        message = 'bicycle_info.inactive_bicycle_confirmation_message';
        break;
      case 'IN_MAINTENANCE':
        message = 'bicycle_info.make_bicycle_in_maintenance_confirmation_message';
        break;
      case 'DELETED':
        message = 'bicycle_info.make_bicycle_deleted_confirmation_message';
        break;
    }

    this.setState({
      ...this.state,
      confirmationWindow: {
        open: true,
        message: message
      },
      status: status
    })
  }

  onOpenPowerLevelHistory() {
    this.setState({
      ...this.state,
      powerLevelHistoryWindow: {
        open: true,
        openConfirmation: false
      }
    })
  }

  onSubmitChangeStatus() {
    this.closeConfirmationModel();
    this.props.changeBicycleStatus(this.props.match.params.systemId,
      this.props.match.params.bicycleId, this.state.status)
  }

  onHandleEdit() {
    this.props.history.push('/system/' + this.props.match.params.systemId
      + '/edit-bicycle/' + this.props.match.params.bicycleId)
  }

  closeConfirmationModel() {
    this.setState({
      ...this.state,
      confirmationWindow: {
        ...this.state.confirmationWindow,
        open: false
      }
    });
  }

  closePowerLevelHistoryModel() {
    this.setState({
      ...this.state,
      powerLevelHistoryWindow: {
        open: false,
        openConfirmation: false
      }
    })
  }

  onClearPowerLevelHistory() {
    this.setState({
      ...this.state,
      powerLevelHistoryWindow: {
        open: false,
        openConfirmation: true
      }
    });
  }

  onSubmitClearPowerLevelHistory() {
    this.closePowerLevelHistoryModel();
    this.props.clearPowerLevelHistory(this.props.match.params.systemId, this.props.match.params.bicycleId)
  }

  render() {
    if (this.props.currentBicycle) {
      const actions = {};
      if (this.props.currentBicycle.powerLevelHistory && this.props.currentBicycle.powerLevelHistory.length > 0) {
        actions.showHistory = this.onOpenPowerLevelHistory.bind(this)
      }

      const formConfig = buildBicycleInfoForm(actions, this.props.currentRole === ADMIN);
      formConfig.title = {
        back: {
          url: this.state.backUrl,
          history: this.props.history,
          location: this.props.location
        }
      }

      if (this.props.currentBicycle.status !== 'DELETED') {
        formConfig.title.actions = <BicycleInfoActions
          isSuperAdmin={this.props.isSuperAdmin}
          bicycle={this.props.currentBicycle}
          onHandleEdit={this.onHandleEdit.bind(this)}
          onHandleChangeStatus={this.onHandleChangeStatus.bind(this)}/>
      }

      for (let i = 0; i < formConfig.fields.length; i++) {
        if (formConfig.fields[i].name === 'powerLevel') {
          formConfig.fields[i].getValue = (data) => {
            if (!data.powerLevel) {
              return 'power_level.none';
            } else if (data.powerLevel >= 80) {
              return <img src={BatteryFull} alt={data.powerLevel} className="battery-icon"/>
            } else if (data.powerLevel >= 50) {
              return <img src={Battery} alt={data.powerLevel} className="battery-icon"/>
            } else if (data.powerLevel < 50) {
              return <img src={BatteryLow} alt={data.powerLevel} className="battery-icon"/>
            }

            return 'power_level.none';
          };
        }
      }


      const history = this.props.currentBicycle.powerLevelHistory ? this.props.currentBicycle.powerLevelHistory : [];

      return (
        <div className="bs-form-container">
          <PagePermission anonymous={false} permission={MANAGER}/>
          <ConfirmationModel
            open={this.state.confirmationWindow.open}
            message={this.state.confirmationWindow.message}
            handleClose={this.closeConfirmationModel.bind(this)}
            handleApply={this.onSubmitChangeStatus.bind(this)}
          />
          <ConfirmationModel
            open={this.state.powerLevelHistoryWindow.openConfirmation}
            message="bicycle_info.clear_power_level_history_confirmation_message"
            handleClose={this.closePowerLevelHistoryModel.bind(this)}
            handleApply={this.onSubmitClearPowerLevelHistory.bind(this)}
          />
          <PowerLevelHistoryModel
            open={this.state.powerLevelHistoryWindow.open}
            history={history}
            handleClose={this.closePowerLevelHistoryModel.bind(this)}
            handleClear={this.onClearPowerLevelHistory.bind(this)}
          />

          <BsForm
            config={formConfig}
            formData={this.props.currentBicycle}
            disabled={true}/>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentBicycle: state.bicycle.currentBicycle,
    currentRole: state.system.currentSystem ? state.system.currentSystem.role : null,
    isSuperAdmin: state.profile.profile != null && state.profile.profile.role != null
      && state.profile.profile.role.indexOf('SUPER_ADMIN') > -1
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeBicycleStatus: (systemId, bicycleId, status) => {
      dispatch(changeBicycleStatus(systemId, bicycleId, status));
    },
    fetchBicycle: (systemId, bicycleId) => {
      dispatch(fetchBicycle(systemId, bicycleId));
    },
    clearPowerLevelHistory: (systemId, bicycleId) => {
      dispatch(clearPowerLevelHistory(systemId, bicycleId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BicycleInfoTab);
