import {combineReducers} from 'redux'

import {appReducer} from "./app-reducer";
import {adminReducer} from "./admin-reducer";
import {bicycleReducer} from "./bicycle-reducer";
import {bikeReportReducer} from "./bike-report-reducer";
import {clientUserReducer} from "./client-user-reducer";
import {geocodeReducer} from "./geocode-reducer";
import {locationReducer} from "./location-reducre";
import {profileReducer} from "./profile-reduces";
import {routeReducer} from "./route-reducer";
import {securityReducer} from './security-reducer'
import {systemReducer} from "./system-reducer";
import {systemConfigReducer} from "./system-config-reducer";
import {rentalReducer} from "./rental-reducer";
import {maintenanceReducer} from "./maintenance-reducer";
import {integrationsReducer} from "./integrations-reducer";
import {maintenancePlanReducer} from "./maintenance-plan-reducer";
import {serviceReducer} from "./service-reducer";
import {statisticsReducer} from "./statistics-reducer";
import {clientAuthReducer} from "./client-auth-reducer";
import {labelReducer} from "./label-reducer";
import {passwordReducer} from "./password-reducer";
import {subscriptionPlanReducer} from "./subscription-plan-reducer";
import {systemSubscriptionReducer} from "./system-subscription-reducer";
import {subscriptionTransactionReducer} from "./subscription-transaction-reducer";
import {systemPaymentSourceReducer} from "./system-payment-source-reducer";
import {rentalRateReducer} from "./rental-rate-reducer";
import {rentalPaymentReducer} from "./rental-payment-reducer";
import {rentalRateCouponReducer} from "./rental-rate-coupon-reducer";

export const rootReducer = combineReducers({
  app: appReducer,
  admin: adminReducer,
  bicycle: bicycleReducer,
  bikeReports: bikeReportReducer,
  geocode: geocodeReducer,
  clientUser: clientUserReducer,
  location: locationReducer,
  profile: profileReducer,
  route: routeReducer,
  security: securityReducer,
  system: systemReducer,
  systemConfig: systemConfigReducer,
  rental: rentalReducer,
  maintenance: maintenanceReducer,
  integrations: integrationsReducer,
  maintenancePlan: maintenancePlanReducer,
  service: serviceReducer,
  statistics: statisticsReducer,
  clientAuth: clientAuthReducer,
  label: labelReducer,
  systemPassword: passwordReducer,
  rentalRate: rentalRateReducer,
  rentalPayment: rentalPaymentReducer,
  rentalRateCoupon: rentalRateCouponReducer,
  subscriptionPlan: subscriptionPlanReducer,
  systemSubscription: systemSubscriptionReducer,
  subscriptionTransaction: subscriptionTransactionReducer,
  systemPaymentSource: systemPaymentSourceReducer
})
