import {priceToString} from "core/utils/priceUtils";

const RENTAL_RATE_COUPON_INFO_FORM = {
  fields: [
    {
      name: 'code',
      label: 'rental_rate_coupon_info.code_field',
      type: 'info',
      styles: {
        marginTop: '1em',
        maxWidth: '500px'
      }
    },
    {
      name: 'status',
      label: 'rental_rate_coupon_info.status_field',
      type: 'info',
      translate: true,
      styles: {
        maxWidth: '500px'
      },
      getValue: (data) => {
        return 'rental_rate_coupon_status.' + data.status;
      }
    },
    {
      name: 'discount',
      label: 'rental_rate_coupon_info.discount_field',
      type: 'info',
      styles: {
        maxWidth: '500px'
      },
      getValue: (data) => {
        if (data.type === 'FIXED_AMOUNT') {
          return priceToString(data.discount, data.currency);
        } else {
          return data.discount + '%';
        }
      }
    },
    {
      name: 'usages',
      label: 'rental_rate_coupon_info.usages_field',
      type: 'info',
      translate: true,
      styles: {
        maxWidth: '500px'
      },
      getValue: (data) => {
        if (data.usageType === 'LIMITED') {
          return data.usages + '';
        } else {
          return 'rental_rate_coupon_usage_type.' + data.usageType;
        }
      }
    },
    {
      name: 'validTo',
      label: 'rental_rate_coupon_info.valid_to_header',
      type: 'info',
      translate: true,
      styles: {
        maxWidth: '500px'
      },
      getValue: (data) => {
        if (data.usageType === 'LIMITED') {
          return data.validTo
        } else {
          return 'rental_rate_coupon_usage_type.' + data.usageType;
        }
      }
    }
  ]
};

export default RENTAL_RATE_COUPON_INFO_FORM;
