import {generateRandomString} from "core/utils/stringUtils";

const buildCreateRentalRateCouponForm = (type, unlimited) => {
  const createRentalRateCouponForm = {
    title: {
      text: 'add_rental_rate_coupon.title'
    },
    submit: {
      label: 'add_rental_rate_coupon.submit_btn'
    },
    fields: [
      {
        name: 'code',
        label: 'add_rental_rate_coupon.code_field',
        type: 'text',
        validators: ['required'],
        errors: ['errors.required_discount_code'],
        defaultValue: generateRandomString(8)
      },
      {
        name: 'type',
        label: 'add_rental_rate_coupon.type_field',
        type: 'select',
        items: [
          {label: 'rental_rate_coupon_type.fixed_amount', value: 'FIXED_AMOUNT', translate: true},
          {label: 'rental_rate_coupon_type.percent', value: 'PERCENT', translate: true}
        ],
        validators: ['required'],
        errors: ['errors.required_discount_type'],
        defaultValue: 'FIXED_AMOUNT'
      },
    ]
  }

  if (type === 'PERCENT') {
    createRentalRateCouponForm.fields.push({
      name: 'discount',
      label: 'add_rental_rate_coupon.discount_percent_field',
      type: 'text',
      validators: [
        'required',
        'matchRegexp:^[0-9]{1,100}$',
        'minNumber:0',
        'maxNumber:100'
      ],
      errors: [
        'errors.required_discount_error',
        'errors.invalid_number_format',
        'errors.min_discount_percent_error',
        'errors.max_discount_percent_error'
      ],
      defaultValue: ''
    });
  } else {
    createRentalRateCouponForm.fields.push({
      name: 'price_grid',
      type: 'grid',
      fields: [
        {
          name: 'discount',
          label: 'add_rental_rate_coupon.discount_field',
          type: 'text',
          validators: [
            'required',
            'matchRegexp:^[0-9]{1,100}$',
            'minNumber:0',
            'maxNumber:999999999'
          ],
          errors: [
            'errors.required_discount_error',
            'errors.invalid_number_format',
            'errors.min_discount_amount_error',
            'errors.max_discount_amount_error'
          ],
          defaultValue: ''
        },
        {
          name: 'currency',
          label: 'add_rental_rate_coupon.currency_field',
          type: 'select',
          items: [
            {label: 'currency.usd', value: 'USD', translate: true},
            {label: 'currency.cad', value: 'CAD', translate: true}
          ],
          validators: ['required'],
          errors: ['errors.required_currency'],
          defaultValue: 'USD'
        },
      ]
    });
  }

  createRentalRateCouponForm.fields.push({
    name: 'unlimited',
    label: 'add_rental_rate_coupon.usage_type_field',
    type: 'checkbox',
    styles: {
      marginRight: '-12px'
    },
    defaultValue: true
  });

  if (!unlimited) {
    createRentalRateCouponForm.fields.push({
      name: 'usages',
      label: 'add_rental_rate_coupon.usages_field',
      type: 'text',
      validators: [
        'required',
        'matchRegexp:^[0-9]{1,100}$',
        'minNumber:0',
        'maxNumber:999999999'
      ],
      errors: [
        'errors.required_usages_error',
        'errors.invalid_number_format',
        'errors.min_usages_error',
        'errors.max_usages_error'
      ],
      defaultValue: ''
    });

    createRentalRateCouponForm.fields.push({
      name: 'validTo',
      label: 'add_rental_rate_coupon.valid_to_field',
      type: 'date',
      validators: [],
      errors: [],
      minDate: new Date(),
      defaultValue: ''
    });
  }

  return createRentalRateCouponForm;
}

export default buildCreateRentalRateCouponForm;
