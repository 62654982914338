import {priceToString} from "core/utils/priceUtils";

const getRentalRatesTable = (t) => {
  return {
    columns: [
      {
        label: 'rental_rate_coupons.code_header',
        field: 'code',
        ordering: true
      },
      {
        label: 'rental_rate_coupons.status_header',
        field: 'status',
        translate: true,
        getValue(raw) {
          return 'rental_rate_coupon_status.' + raw.status;
        }
      },
      {
        label: 'rental_rate_coupons.discount_header',
        field: 'discount',
        ordering: true,
        getValue(raw) {
          if (raw.type === 'FIXED_AMOUNT') {
            return priceToString(raw.discount, raw.currency);
          } else {
            return raw.discount + '%';
          }
        }
      },
      {
        label: 'rental_rate_coupons.usages_header',
        field: 'usages',
        translate: true,
        ordering: false,
        getValue(raw) {
          if (raw.usageType === 'LIMITED') {
            return raw.usages;
          } else {
            return 'rental_rate_coupon_usage_type.' + raw.usageType;
          }
        }
      },
      {
        label: 'rental_rate_coupons.valid_to_header',
        field: 'validTo',
        translate: true,
        ordering: false,
        getValue: (raw) => {
          if (raw.usageType === 'LIMITED') {
            return raw.validTo
          } else {
            return 'rental_rate_coupon_usage_type.' + raw.usageType;
          }
        }
      }
    ]
  }
}

export default getRentalRatesTable;
