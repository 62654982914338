import React from "react";
import {Trans} from "react-i18next";
import Button from "@material-ui/core/Button";
import BsFormCheckbox from "components/form/fields/BsFormCheckbox";
import BsValidatorForm from "components/form/BsValidatorForm";
import BsFormTitle from "components/form/title/BsFormTitle";
import BsFormActions from "components/form/actions/BsFormActions";
import BsFormSubmitAction from "components/form/actions/BsFormSubmitAction";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import AddEmailDomainModel from "./AddEmailDomainModel";
import BsFormTextField from "components/form/fields/BsFormTextField";
import ConfirmationModel from "components/model/ConfirmationModel";
import RemoveIcon from 'assets/img/remove.svg';

import './edit-user-settings-form.scss'

class EditUserSettingsForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: props.data,
            needClientPayment: false,
            clientPassword: false,
            openAddModel: false,
            openRemoveModel: false,
            removingItem: null
        }
    }

    handleChange(field, e) {
        const {fields} = this.state;
        fields[field] = e.target.value;
        this.setState({fields});

        if (this.props.onHandleChange) {
            this.props.onHandleChange(fields)
        }
    }

    handleChangeCheckbox(field, e) {
        const {fields} = this.state;
        fields[field] = !fields[field];
        this.setState({fields});
    }

    onHandleSubmit(fields, e) {
        this.props.onHandleSubmit(fields, e)
    }

    openAddListItemWindow() {
        this.setState({
            ...this.state,
            openAddModel: true
        })
    }

    closeAddEmailDomainWindow() {
        this.setState({
            ...this.state,
            openAddModel: false
        })
    }

    submitAddEmailDomainsWindow(newValue) {
        this.closeAddEmailDomainWindow()
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                emails: [
                    ...this.state.fields.emails,
                    newValue
                ]
            }
        })
    }

    openRemoveListItemWindow(index) {
        this.setState({
            ...this.state,
            openRemoveModel: true,
            removingItem: index
        })
    }

    closeRemoveListItemWindow() {
        this.setState({
            ...this.state,
            openRemoveModel: false,
            removingItem: null
        })
    }

    submitRemoveListItemWindow() {
        this.state.fields.emails.splice(this.state.removingItem, 1);
        this.setState({
            ...this.state,
            openRemoveModel: false,
            removingItem: null,
            fields: {
                ...this.state.fields,
                emails: this.state.fields.emails
            }
        })
    }

    render() {
        const fields = [];
        fields.push(
            <BsFormTextField
                key="maxOpenRentals"
                label="edit_rental_config.max_open_rentals_field"
                name="maxOpenRentals"
                type="text"
                onHandleChange={this.handleChange.bind(this, "maxOpenRentals")}
                value={this.state.fields.maxOpenRentals}
                validators={[
                    'required',
                    'matchRegexp:^[0-9]{1,3}$',
                    'minNumber:1',
                    'maxNumber:100'
                ]}
                errors={[
                    'errors.required_number',
                    'errors.invalid_number_format',
                    'errors.min_number_error',
                    'errors.max_number_rentals_error'
                ]}/>
        );
        fields.push(
            <BsFormCheckbox
                key="needClientPayment"
                label="edit_user_config.need_client_payment_field"
                name="needClientPayment"
                onHandleChange={this.handleChangeCheckbox.bind(this, "needClientPayment")}
                value={this.state.fields.needClientPayment}/>
        );
        fields.push(
            <BsFormCheckbox
                key="clientPassword"
                label="edit_user_config.client_password_field"
                name="clientPassword"
                onHandleChange={this.handleChangeCheckbox.bind(this, "clientPassword")}
                value={this.state.fields.clientPassword}/>
        );
        fields.push(
            <BsFormCheckbox
                key="enable"
                label="edit_user_config.enable_field"
                name="enable"
                onHandleChange={this.handleChangeCheckbox.bind(this, "enable")}
                value={this.state.fields.enable}/>
        );

        if (this.state.fields.enable) {
            fields.push(
                <BsFormCheckbox
                    key="emailVerification"
                    label="edit_user_config.enable_email_notification_field"
                    name="acceptAll"
                    onHandleChange={this.handleChangeCheckbox.bind(this, "emailVerification")}
                    value={this.state.fields.emailVerification}/>
            );

            fields.push(
                <BsFormCheckbox
                    key="acceptAll"
                    label="edit_user_config.accept_all_field"
                    name="acceptAll"
                    onHandleChange={this.handleChangeCheckbox.bind(this, "acceptAll")}
                    value={this.state.fields.acceptAll}/>
            );

            if (!this.state.fields.acceptAll) {
                fields.push(
                    <BsFormCheckbox
                        key="enableEmailRestrictions"
                        label="edit_user_config.enable_emails_field"
                        name="enableEmailRestrictions"
                        onHandleChange={this.handleChangeCheckbox.bind(this, "enableEmailRestrictions")}
                        value={this.state.fields.enableEmailRestrictions}/>
                );

                if (this.state.fields.enableEmailRestrictions) {
                    let addItemBtn = null;
                    if (!this.state.fields.emails) {
                        this.state.fields.emails = [];
                    }
                    if (this.state.fields.emails.length < 50) {
                        addItemBtn = <Button onClick={this.openAddListItemWindow.bind(this)}
                                             className="edit-user-settings__add-email"
                                             variant="outlined">
                            <Trans i18nKey="general.add"/>
                        </Button>
                    }

                    fields.push(
                        <div className="bs-form__info edit-user-settings__emails-container" key="emailRestrictions">
                            <div className="bs-form__info-label">
                                <Trans i18nKey="edit_user_config.emails_field"/>
                            </div>
                            <div>
                                {this.state.fields.emails.map((value, index) =>
                                    <div key={value + '-email-field-' + index} className="edit-user-settings__emails-value">{value}
                                        <Button onClick={this.openRemoveListItemWindow.bind(this, index)}>
                                            <RemoveIcon/>
                                        </Button></div>)}
                                {addItemBtn}
                            </div>
                        </div>
                    );
                }
            }
        }

        return (
            <div className="edit-user-settings">
                <AddEmailDomainModel
                    open={this.state.openAddModel}
                    handleClose={this.closeAddEmailDomainWindow.bind(this)}
                    handleSubmit={this.submitAddEmailDomainsWindow.bind(this)}
                />
                <ConfirmationModel
                    open={this.state.openRemoveModel}
                    message="edit_user_config.remove_email_domain_title"
                    handleClose={this.closeRemoveListItemWindow.bind(this)}
                    handleApply={this.submitRemoveListItemWindow.bind(this)}
                />

                <BsValidatorForm ref={this.props.formRef}
                                 onHandleSubmit={this.onHandleSubmit.bind(this, this.state.fields)}>
                    <BsFormTitle title={{
                        text: "edit_user_config.title",
                        size: 'm',
                        back: {
                            history: this.props.history,
                            location: this.props.location,
                            url: this.props.backUrl
                        }
                    }}/>

                    {fields}

                    <BsFormActions>
                        <BsFormCancelAction history={this.props.history}
                                            location={this.props.location}
                                            url={this.props.backUrl}/>
                        <BsFormSubmitAction label="edit_user_config.submit_btn"/>
                    </BsFormActions>
                </BsValidatorForm>
            </div>
        )
    }
}

export default EditUserSettingsForm;
