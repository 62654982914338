const BICYCLES_TABLE = {
  columns: [
    {
      label: 'bicycles.number_header',
      field: 'number',
      ordering: true
    },
    {
      label: 'bicycles.model_header',
      field: 'model',
      ordering: true
    },
    {
      label: 'bicycles.status_header',
      field: 'status',
      ordering: true,
      translate: true,
      getValue(raw) {
        return 'bike_status.' + raw.status;
      }
    },
    {
      label: 'bicycles.location_header',
      field: 'location.label',
      getValue: (raw) => {
        return raw.location.label
      },
      ordering: true
    },
    {
      label: 'bicycles.serial_number_header',
      field: 'serialNumber',
      ordering: true
    },
    {
      label: 'bicycles.lock_header',
      field: 'lockMacAddress',
      ordering: true
    },
    {
      label: 'bicycles.power_level_header',
      field: 'powerLevel',
      ordering: true,
    },
    {
      label: 'bicycles.last_scan_header',
      field: 'lastScan',
      ordering: true,
      getValue(raw) {
        return raw.lastScan ? raw.lastScan : '-'
      }
    }
  ],
  footerLabel: 'bicycles.footer_label'
}

export default BICYCLES_TABLE;
