import React from 'react';
import {connect} from "react-redux";
import {MANAGER} from "core/utils/permissions";
import {exportBicyclesToCsv, loadBicycles} from "core/actions/bicycle-actions";
import PagePermission from "components/permission/PagePermission";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeaderFilters from "components/panel/header/BsPanelHeaderFilters";
import BsDataTable from "components/data-table/BsDataTable";
import BsPanelTitle from "components/panel/header/BsPanelTitle";
import Permitted from "components/permission/Permitted";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import LocationBicyclesFilters from "./LocationBicyclesFilters";
import LOCATION_BICYCLES_INFO_FORM from "./location-bicycles-info-form";
import {Trans} from "react-i18next";
import Button from "@material-ui/core/Button";
import BatteryFull from "assets/img/battery_full.png";
import Battery from "assets/img/battery_mid.png";
import BatteryLow from "assets/img/battery_low.png";

const queryString = require('query-string');

class LocationBicyclesTab extends React.Component {

  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      sort: query.sort,
      direction: query.direction,
      fields: {
        number: query.number != null ? query.number : '',
        serialNumber: query.serialNumber != null ? query.serialNumber : ''
      }
    };

    this.config = {
      ...LOCATION_BICYCLES_INFO_FORM
    }
    for (let i = 0; i < this.config.columns.length; i++) {
      if (this.config.columns[i].field === 'powerLevel') {
        this.config.columns[i].getValue = (raw) => {
          if (!raw.powerLevel) {
            return 'none';
          } else if (raw.powerLevel >= 80) {
            return <img src={BatteryFull} alt={raw.powerLevel} className="battery-icon"/>
          } else if (raw.powerLevel >= 50) {
            return <img src={Battery} alt={raw.powerLevel} className="battery-icon"/>
          } else if (raw.powerLevel < 50) {
            return <img src={BatteryLow} alt={raw.powerLevel} className="battery-icon"/>
          }

          return 'none';
        };
      }
    }

    this.onLoadData(query);
  }

  onRequestSort = (params) => {
    this.setState({
      sort: params.sort,
      direction: params.direction
    });
    this.onLoadData(params);
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
  }

  onHandleFilter = () => {
    const params = queryString.parse(this.props.location.search);
    params.number = this.state.fields.number;
    params.serialNumber = this.state.fields.serialNumber;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
  }

  onHandleClear = () => {
    const params = queryString.parse(this.props.location.search);
    delete params.number;
    params.page = '0';
    this.props.history.push('?' + queryString.stringify(params));
    this.onLoadData(params)
    this.setState({
      ...this.state,
      fields: {
        number: '',
        serialNumber: ''
      }
    })
  }

  onLoadData = (query) => {
    this.props.loadBicycles(this.props.match.params.systemId, this.prepareQuery(query));
  }

  onRowClick = (row) => {
    this.props.history.push('/system/' + this.props.match.params.systemId + '/bicycle/' + row.id)
  }

  export = () => {
    const query = queryString.parse(this.props.location.search);
    this.props.exportBicyclesToCsv(this.props.match.params.systemId, this.prepareQuery(query));
  }

  prepareQuery = (query) => {
    if (query.sort) {
      query.sort = query.sort + ',' + query.direction;
    }
    query['location.id'] = this.props.match.params.locationId;
    return query
  }

  render() {
    return (
      <div style={{padding: '1em'}}>
        <PagePermission anonymous={false} permission={MANAGER}/>
        <BsPanelLoader show={this.props.loading}/>
        <BsPanelHeader>
          <BsPanelTitle action={
            <Permitted for={MANAGER}>
              <Button variant="outlined" type="button" onClick={this.export}>
                <Trans i18nKey="general.export"/>
              </Button>
            </Permitted>
          }>
            <Trans i18nKey="bicycles.title"/>
          </BsPanelTitle>
        </BsPanelHeader>

        <BsPanelHeaderFilters onHandleFilter={this.onHandleFilter.bind(this)}
                              onHandleClear={this.onHandleClear.bind(this)}>
          <LocationBicyclesFilters fields={this.state.fields}
                                   onHandleChange={this.handleChange.bind(this)}/>
        </BsPanelHeaderFilters>

        <BsDataTable location={this.props.location}
                     history={this.props.history}
                     order={this.state.direction}
                     orderBy={this.state.sort}
                     settings={this.config}
                     content={this.props.bicycles}
                     onRowClick={this.onRowClick}
                     onChangePage={this.onLoadData}
                     onChangeSize={this.onLoadData}
                     onRequestSort={this.onRequestSort}/>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    bicycles: state.bicycle.bicycles,
    loading: state.bicycle.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadBicycles: (systemId, query) => {
      dispatch(loadBicycles(systemId, query));
    },
    exportBicyclesToCsv: (systemId, query) => {
      dispatch(exportBicyclesToCsv(systemId, query));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationBicyclesTab);
