import React from "react";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import BsDataTable from "components/data-table/BsDataTable";
import BsTabPanel from "components/panel/BsTabPanel";
import BsPanelHeaderActions from "components/panel/header/BsPanelHeaderActions";
import BsPanelHeader from "components/panel/header/BsPanelHeader";
import BsPanelLoader from "components/panel/loader/BsPanelLoader";
import BsPanelHeaderFilters from "components/panel/header/BsPanelHeaderFilters";
import PagePermission from "components/permission/PagePermission";
import {loadAdmins, exportAdminsToCsv} from "core/actions/admin-actions";
import {ADMIN} from "core/utils/permissions";
import {TABLE_SETTINGS} from "./admins-table-settings";
import PlusIcon from 'assets/img/plus.svg';
import AdminSettingsFilters from "./AdminSettingsFilters";
import {Trans} from "react-i18next";

const queryString = require('query-string');

class AdminsSettings extends React.Component {

    constructor(props) {
        super(props);
        const query = queryString.parse(this.props.location.search);
        this.state = {
            sort: query.sort,
            direction: query.direction,
            fields: {
                name: query.name != null ? query.name : '',
                email: query.email != null ? query.email : '',
                status: query.status != null ? query.status : ''
            }
        };

        this.onLoadData(query);
    }

    onRequestSort = (params) => {
        this.setState({
            sort: params.sort,
            direction: params.direction
        });
        this.onLoadData(params);
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({fields});
    }

    onHandleFilter = () => {
        const params = queryString.parse(this.props.location.search);
        params.name = this.state.fields.name;
        params.email = this.state.fields.email;
        params.status = this.state.fields.status;
        params.page = '0';
        this.props.history.push('?' + queryString.stringify(params));
        this.onLoadData(params)
    }

    onHandleClear = () => {
        const params = queryString.parse(this.props.location.search);
        delete params.name;
        delete params.email;
        delete params.status;
        params.page = '0';
        this.props.history.push('?' + queryString.stringify(params));
        this.onLoadData(params)
        this.setState({
            ...this.state,
            fields: {
                name: '',
                email: '',
                status: []
            }
        })
    }

    onLoadData = (query) => {
        this.props.loadAdmins(this.props.match.params.systemId, this.prepareQuery(query));
    }

    addAdmin = () => {
        this.props.history.push('/system/' + this.props.match.params.systemId + '/admins/add')
    }

    onRowClick = (row) => {
        this.props.history.push('/system/' + this.props.match.params.systemId + '/admins/' + row.id + '/info')
    }

    export = () => {
        const query = queryString.parse(this.props.location.search);
        this.props.exportAdminsToCsv(this.props.match.params.systemId, this.prepareQuery(query));
    }

    prepareQuery = (query) => {
        if (query.sort) {
            query.sort = query.sort + ',' + query.direction;
        }
        if (query.name) {
            query['user.firstName'] = query.name
            query['user.lastName'] = query.name
        }
        if (query.email) {
            query['user.email'] = query.email
        }
        if (query.status) {
            if (query.status === 'PENDING_EMAIL') {
                query['user.status'] = 'PENDING';
                delete query.status;
            } else {
                query['user.status'] = 'ACTIVE';
            }
        }

        return query
    }

    render() {
        return (
            <BsTabPanel>
                <PagePermission anonymous={false} permission={ADMIN}/>
                <BsPanelLoader show={this.props.loading}/>
                <BsPanelHeader>
                    <BsPanelHeaderActions>
                        <Button variant="contained" color="primary" type="submit" onClick={this.addAdmin}>
                            <PlusIcon/>&nbsp;<Trans i18nKey="general.add"/>
                        </Button>
                        <Button variant="outlined" type="button" onClick={this.export}>
                            <Trans i18nKey="general.export"/>
                        </Button>
                    </BsPanelHeaderActions>
                </BsPanelHeader>

                <BsPanelHeaderFilters onHandleFilter={this.onHandleFilter.bind(this)}
                                      onHandleClear={this.onHandleClear.bind(this)}>
                    <AdminSettingsFilters fields={this.state.fields}
                                          onHandleChange={this.handleChange.bind(this)}/>
                </BsPanelHeaderFilters>

                <BsDataTable location={this.props.location}
                             history={this.props.history}
                             order={this.state.direction}
                             orderBy={this.state.sort}
                             settings={TABLE_SETTINGS}
                             content={this.props.admins}
                             onRowClick={this.onRowClick}
                             onChangePage={this.onLoadData}
                             onChangeSize={this.onLoadData}
                             onRequestSort={this.onRequestSort}/>
            </BsTabPanel>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        admins: state.admin.admins,
        loading: state.admin.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadAdmins: (systemId, query) => {
            dispatch(loadAdmins(systemId, query));
        },
        exportAdminsToCsv: (systemId, query) => {
            dispatch(exportAdminsToCsv(systemId, query));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminsSettings);
