import React from 'react'
import Button from '@material-ui/core/Button'
import Permitted from "components/permission/Permitted";
import {ADMIN} from 'core/utils/permissions'
import {useTranslation} from "react-i18next";
import SelectIcon from "assets/img/select-icon.svg";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import MenuItem from "@material-ui/core/MenuItem";

const RentalRateCouponInfoTabActions = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const {t} = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleActivateClick = (event) => {
        handleClose();
        props.onHandleActivate()
    };

    const handleDeleteClick = (event) => {
        handleClose();
        props.onHandleDelete()
    };

    const handleCancelClick = (event) => {
        handleClose();
        props.onHandleCancel()
    };

    const handleEditClick = (event) => {
        handleClose();
        props.onHandleEdit()
    };

    let btns = [];
    if (props.coupon && props.coupon.status === 'CREATED') {
        btns.push(<MenuItem key="activate" onClick={() => handleActivateClick()}>
            {t('rental_rate_coupon_info.activate_btn')}
        </MenuItem>)
        btns.push(<MenuItem key="edit" onClick={() => handleEditClick()}>
            {t('rental_rate_coupon_info.edit_btn')}
        </MenuItem>)
        btns.push(<MenuItem key="delete" onClick={() => handleDeleteClick()}>
            {t('rental_rate_coupon_info.delete_btn')}
        </MenuItem>)
    }
    if (props.coupon && props.coupon.status === 'ACTIVE') {
        btns.push(<MenuItem key="edit" onClick={() => handleEditClick()}>
            {t('rental_rate_coupon_info.edit_btn')}
        </MenuItem>)
        btns.push(<MenuItem key="cancel" onClick={() => handleCancelClick()}>
            {t('rental_rate_coupon_info.cancel_btn')}
        </MenuItem>)
    }

    if (btns.length > 0) {
        return (
          <Permitted for={ADMIN}>
              <Button aria-controls="admin-info-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                      variant="contained"
                      color="primary">
                  {t('general.actions')} <SelectIcon className="title-actions__select-icon"/>
              </Button>
              <Menu
                id="admin-info-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}>
                  {btns}
              </Menu>
          </Permitted>
        )
    } else {
        return '';
    }
}

export default RentalRateCouponInfoTabActions
