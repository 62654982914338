import React from "react";
import Grid from "@material-ui/core/Grid";
import BsValidatorForm from './BsValidatorForm'
import BsFormTitle from "./title/BsFormTitle";
import BsFormSubmitAction from "./actions/BsFormSubmitAction";
import BsFormActions from "./actions/BsFormActions";
import BsFormSubTitle from "./fields/BsFormSubTitle";
import BsFormTextField from "./fields/BsFormTextField";
import BsFormCheckbox from "./fields/BsFormCheckbox";
import BsFormInfoField from "./fields/BsFormInfoField";
import BsFormPriceInfoField from "./fields/BsFormPriceInfoField";
import BsFormNotes from "./fields/BsFormNotes";
import BsFormPhoneField from "./fields/BsFormPhoneField";
import {deepIsEqual} from "core/utils/objectsUtils";
import BsFormSelectField from "./fields/BsFormSelectField";
import BsFormGoogleAutocompleteField from "./fields/BsFormGoogleAutocompleteField";
import BsFormSystemsAutocompleteField from "./fields/BsFormSystemsAutocompleteField";
import BsFormAdminsAutocompleteField from "./fields/BsFormAdminsAutocompleteField";
import BsFormMaskTextField from "./fields/BsFormMaskTextField";
import BsTextEditorField from "./fields/BsTextEditorField";
import BsFormLink from "./fields/BsFormLink";
import BsFormDesc from "./fields/BsFormDesc";
import BsFormReferenceLinkField from "./fields/BsFormReferenceLinkField";
import BsFormDelimiter from "./BsFormDelimiter";
import BsFormAnyField from "./fields/BsFormAnyField";
import BsFormDate from "./fields/BsFormDate";
import BsFormCurdNumberField from "./fields/BsFormCurdNumberField";
import PropTypes from "prop-types";
import Row from "../layout/Row";
import Col from "../layout/Col";

class BsForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {fields: BsForm.buildFields(props.config.fields, props.formData)}
  }

  static getDerivedStateFromProps(props, state) {
    if (!deepIsEqual(props.formData, state.formData)) {
      return {
        ...state,
        fields: BsForm.buildFields(props.config.fields, props.formData),
        formData: props.formData
      };
    } else {
      return null
    }
  }

  static buildFields(fields, formData) {
    let result = {};
    fields.forEach(field => {
      if (field.type === 'grid') {
        result = {
          ...result,
          ...BsForm.buildFields(field.fields, formData)
        }
      } else {
        result[field.name] = BsForm.getFieldValue(field, formData)
      }
    });
    return result
  }

  static getFieldValue(field, data) {
    if (data && data[field.name] != null) {
      return data[field.name]
    } else {
      return field.defaultValue
    }
  }

  handleChange(field, e) {
    const {fields} = this.state;
    fields[field] = e.target ? e.target.value : e.format('yyyy MMM DD');
    this.setState({fields});

    if (this.props.onHandleChange) {
      this.props.onHandleChange(fields)
    }
  }

  handleChangeCheckbox(field, e) {
    const {fields} = this.state;
    fields[field] = !fields[field];
    this.setState({fields});

    if (this.props.onHandleChange) {
      this.props.onHandleChange(fields)
    }
  }

  refreshData() {
    const fields = BsForm.buildFields(this.props.config.fields, this.props.formData);
    if (!deepIsEqual(fields, this.state.fields)) {
      this.setState({
        ...this.state,
        fields
      });
    }
  }

  renderField(field, index) {
    if (field.showFn && !field.showFn(this.state.fields)) {
      return null;
    }

    switch (field.type) {
      case 'any':
        return <BsFormAnyField
            key={field.name + index}
            className={field.className}
            label={field.label}
            value={field.getValue ? field.getValue(this.state.formData) : field.value}
            onClick={field.action}/>

      case 'grid':
        return <Grid container spacing={3} key={field.name + index}>
          {field.fields.map((f, i) => this.renderField({
            type: 'grid-item',
            value: f
          }, i))}
        </Grid>
      case 'grid-item':
        return <Grid item sm xs key={field.value.name + '-greed-item-' + index} className={field.value.name + '-greed-item-' + index}>
          <div key={field.value.name + '-container-' + index} style={field.value.styles}>
            {this.renderField(field.value, index)}
          </div>
        </Grid>

      case 'subTitle':
        return <BsFormSubTitle key={field.name + index} text={field.label} args={field.args}/>

      case 'text':
      case 'password':
        return <BsFormTextField
            key={field.name + index}
            label={field.label}
            name={field.name}
            type={field.type}
            multiline={field.multiline}
            disabled={field.disabled !== undefined ? field.disabled : this.props.disabled}
            onHandleChange={this.handleChange.bind(this, field.name)}
            value={this.state.fields[field.name]}
            validators={field.validators}
            errors={field.errors}/>

      case 'curd-number':
        return <BsFormCurdNumberField
            key={field.name + index}
            label={field.label}
            name={field.name}
            type={field.type}
            multiline={field.multiline}
            disabled={field.disabled !== undefined ? field.disabled : this.props.disabled}
            onHandleChange={this.handleChange.bind(this, field.name)}
            value={this.state.fields[field.name]}
            validators={field.validators}
            errors={field.errors}/>

      case 'date':
        return <BsFormDate
            key={field.name + index}
            label={field.label}
            name={field.name}
            disabled={this.props.disabled}
            onHandleChange={this.handleChange.bind(this, field.name)}
            minDate={field.minDate}
            value={this.state.fields[field.name]}
            validators={field.validators}
            errors={field.errors}/>

      case 'masked-text':
        return <BsFormMaskTextField
            key={field.name + index}
            label={field.label}
            name={field.name}
            type={field.type}
            mask={field.mask}
            disabled={this.props.disabled}
            onHandleChange={this.handleChange.bind(this, field.name)}
            value={this.state.fields[field.name]}
            validators={field.validators}
            errors={field.errors}/>

      case 'select':
        return <BsFormSelectField
            key={field.name + index}
            label={field.label}
            name={field.name}
            items={field.items}
            noneLabel={field.noneLabel}
            disabled={this.props.disabled}
            onHandleChange={this.handleChange.bind(this, field.name)}
            value={this.state.fields[field.name]}
            validators={field.validators}
            errors={field.errors}/>

      case 'phone':
        return <BsFormPhoneField
            key={field.name + index}
            label={field.label}
            name={field.name}
            type={field.type}
            disabled={this.props.disabled}
            onHandleChange={this.handleChange.bind(this, field.name)}
            value={this.state.fields[field.name]}
            validators={field.validators}
            errors={field.errors}/>

      case 'google-autocomplete':
        return <BsFormGoogleAutocompleteField
            key={field.name + index}
            label={field.label}
            name={field.name}
            type={field.type}
            disabled={this.props.disabled}
            onHandleChange={this.handleChange.bind(this, field.name)}
            value={this.state.fields[field.name]}
            validators={field.validators}
            errors={field.errors}/>

      case 'systems-autocomplete':
        return <BsFormSystemsAutocompleteField
            key={field.name + index}
            label={field.label}
            name={field.name}
            type={field.type}
            disabled={this.props.disabled}
            onHandleChange={this.handleChange.bind(this, field.name)}
            value={this.state.fields[field.name]}
            validators={field.validators}
            errors={field.errors}/>

      case 'admins-autocomplete':
        return <BsFormAdminsAutocompleteField
            key={field.name + index}
            label={field.label}
            name={field.name}
            type={field.type}
            disabled={this.props.disabled}
            onHandleChange={this.handleChange.bind(this, field.name)}
            value={this.state.fields[field.name]}
            validators={field.validators}
            errors={field.errors}/>

      case 'checkbox':
        return <BsFormCheckbox
            key={field.name + index}
            label={field.label}
            name={field.name}
            disabled={this.props.disabled}
            onHandleChange={this.handleChangeCheckbox.bind(this, field.name)}
            value={this.state.fields[field.name]}/>

      case 'text-editor':
        return <BsTextEditorField
            key={field.name + index}
            label={field.label}
            name={field.name}
            disabled={this.props.disabled}
            onHandleChange={this.handleChange.bind(this, field.name)}
            value={this.state.fields[field.name]}
        />

      case 'info':
        return <BsFormInfoField
            key={field.name + index}
            label={field.label}
            valueDecoration={field.valueDecoration}
            translate={field.translate}
            value={field.getValue ? field.getValue(this.state.formData) : this.state.fields[field.name]}
            styles={field.getStyle ? field.getStyle(this.state.formData) : {}}
            onClick={field.action}/>

      case 'price':
        return <BsFormPriceInfoField
            key={field.name + index}
            label={field.label}
            valueDecoration={field.valueDecoration}
            translate={field.translate}
            value={field.getValue ? field.getValue(this.state.formData) : this.state.fields[field.name]}
            currency={field.currency ? this.props.formData[field.currency] : 'USD'}
            onClick={field.action}/>

      case 'notes':
        return <BsFormNotes
            key={field.name + index}
            label={field.label}/>

      case 'desc':
        return <BsFormDesc
            key={field.name + index}
            title={field.title}
            desc={field.desc}/>

      case 'link':
        return <BsFormLink
            key={field.name + index}
            translate={field.translate}
            styles={field.styles}
            onHandleClick={field.onClick}
            label={field.label}/>

      case 'reference-link':
        return <BsFormReferenceLinkField
            key={field.name + index}
            label={field.label}
            valueDecoration={field.valueDecoration}
            translate={field.translate}
            value={field.getValue ? field.getValue(this.state.formData) : this.state.fields[field.name]}
            link={field.link(this.state.formData)}/>

      case 'delimiter':
        return <BsFormDelimiter
            key={field.name + index}/>

      default:
        return null
    }
  }

  onHandleSubmit(fields, e) {
    if (this.props.onHandleSubmit) {
      this.props.onHandleSubmit(fields, e)
    }
  }

  render() {
    let actions = null;
    if (!this.props.disabled) {
      actions = <BsFormActions>
        {this.props.cancelAction}
        <BsFormSubmitAction label={this.props.config.submit.label}/>
      </BsFormActions>
    } else {
      this.refreshData()
    }

    const fields = this.props.config.fields.map((field, index) => {
      return (
          <div key={field.name + '-container-' + index} style={field.styles}>
            {this.renderField(field, index)}
          </div>
      )
    });

    let content;
    if (this.props.additionalContent) {
      content = <Row spacing={true}>
        <Col size={6}>
          {fields}
        </Col>
        <Col size={6}>
          {this.props.additionalContent}
        </Col>
      </Row>
    } else {
      content = fields;
    }

    return (
        <BsValidatorForm ref={this.props.formRef}
                         onHandleSubmit={this.onHandleSubmit.bind(this, this.state.fields)}>
          <BsFormTitle title={this.props.config.title}/>
          {content}
          {actions}
        </BsValidatorForm>
    )
  }
}

BsForm.addValidationRule = (type, rule) => {
  BsValidatorForm.addValidationRule(type, rule);
}

BsForm.removeValidationRule = (type) => {
  BsValidatorForm.removeValidationRule(type);
}

BsForm.propTypes = {
  config: PropTypes.object.isRequired,
  formData: PropTypes.object,
  onHandleSubmit: PropTypes.func,
  onHandleChange: PropTypes.func,
  cancelAction: PropTypes.any,
  disabled: PropTypes.bool,
  additionalContent: PropTypes.any
};

export default BsForm;
