import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import BsFormCancelAction from "components/form/actions/BsFormCancelAction";
import BsForm from "components/form/BsForm";
import PagePermission from "components/permission/PagePermission";
import {ADMIN} from "core/utils/permissions";
import {createRentalRateCoupon} from "core/actions/rental-rate-coupon-actions";
import buildCreateRentalRateCouponForm from "./add-rental-rate-coupon-form";

import "./add-rental-rate-coupon.scss";
import moment from "moment/moment";

class AddRentalRateCoupon extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: {
        code: '',
        type: 'FIXED_AMOUNT',
        discount: '',
        currency: 'USD',
        usages: '1',
        unlimited: true,
        validTo: ''
      }
    };
    this.backUrl = '/system/' + this.props.match.params.systemId + '/rental-rates/coupons';
  }

  handleSubmit(fields, e) {
    this.props.createRentalRateCoupon(this.props.match.params.systemId, {
      code: fields.code,
      type: fields.type,
      discount: fields.discount,
      currency: fields.type === 'FIXED_AMOUNT' ? fields.currency : undefined,
      usageType: fields.unlimited ? 'UNLIMITED' : 'LIMITED',
      usages: fields.unlimited ? undefined : fields.usages,
      validTo: fields.unlimited ? undefined : moment(new Date(fields.validTo)).format('YYYY-MM-DD')
    });
  }

  handleChange(fields) {
    this.setState({
      ...this.state,
      fields: fields
    });
  }

  render() {
    const formConfig = buildCreateRentalRateCouponForm(this.state.fields.type, this.state.fields.unlimited)

    return (
      <div className="add-rental-rate-coupon">
        <BsFormPanel>
          <PagePermission anonymous={false} permission={ADMIN}/>
          <BsForm
            config={formConfig}
            onHandleSubmit={this.handleSubmit.bind(this)}
            onHandleChange={this.handleChange.bind(this)}
            cancelAction={
              <BsFormCancelAction history={this.props.history}
                                  location={this.props.location}
                                  url={this.backUrl}/>
            }/>
        </BsFormPanel>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    createRentalRateCoupon: (systemId, data) => {
      dispatch(createRentalRateCoupon(systemId, data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRentalRateCoupon);
