import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateMomentUtils from '@date-io/moment';

const BsFormDate = (props) => {
  const {t} = useTranslation();

  return (
      <MuiPickersUtilsProvider utils={DateMomentUtils}>
        <DatePicker
            label={t(props.label)}
            name={props.name}
            disabled={props.disabled}
            onChange={props.onHandleChange.bind(this)}
            minDate={props.minDate}
            value={props.value}
            format="yyyy/MM/DD"
            className="form-control" />
      </MuiPickersUtilsProvider>
  )
}

BsFormDate.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onHandleChange: PropTypes.func,
  value: PropTypes.any,
  validators: PropTypes.array,
  errors: PropTypes.array
};

export default BsFormDate;
