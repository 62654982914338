import React from 'react';
import {connect} from "react-redux";
import BsFormPanel from "components/form/BsFormPanel";
import PagePermission from "components/permission/PagePermission";
import ConfirmationModel from "components/model/ConfirmationModel";
import {ADMIN} from "core/utils/permissions";
import {fetchSystemConfig, updateClientPolicy} from "core/actions/system-config-actions";
import EditUserSettingsForm from "./EditUserSettingsForm";

class EditUserSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openConfirmationWindow: false
    };
    this.backUrl = '/system/' + this.props.match.params.systemId + '/settings/user';
    this.fields = {};
    props.fetchSystemConfig(props.match.params.systemId);
  }

  handleSubmit(fields, e) {
    this.fields = fields;
    this.setState({openConfirmationWindow: true})
  }

  handleUpdate() {
    this.setState({openConfirmationWindow: false})
    this.props.updateClientPolicy(this.props.match.params.systemId, {
      needClientPayment: this.fields.needClientPayment,
      maxOpenRentals: this.fields.maxOpenRentals,
      clientPassword: this.fields.clientPassword,
      enable: this.fields.enable && (this.fields.acceptAll || this.fields.enableEmailRestrictions),
      emailVerification: this.fields.emailVerification,
      acceptAll: this.fields.acceptAll,
      emailPolicy: {
        enable: this.fields.enable && this.fields.enableEmailRestrictions,
        emails: this.fields.emails
      }
    }, this.backUrl)
  }

  handleChange(fields) {
    this.fields = fields;
  }

  render() {
    if (this.props.systemConfig) {
      const data = {
        ...(this.props.systemConfig.clientAcceptancePolicy ? this.props.systemConfig.clientAcceptancePolicy : {}),
        needClientPayment: this.props.systemConfig.needClientPayment,
        clientPassword: this.props.systemConfig.clientPassword,
        maxOpenRentals: this.props.systemConfig.maxOpenRentals
      }

      if (this.props.systemConfig.clientAcceptancePolicy && this.props.systemConfig.clientAcceptancePolicy.emailPolicy) {
        data.enableEmailRestrictions = this.props.systemConfig.clientAcceptancePolicy.emailPolicy.enable;
        data.emails = this.props.systemConfig.clientAcceptancePolicy.emailPolicy.emails;
      } else {
        data.emails = []
      }

      return (
          <BsFormPanel size="s">
            <PagePermission anonymous={false} permission={ADMIN}/>
            <ConfirmationModel
                open={this.state.openConfirmationWindow}
                message="edit_user_config.confirmation_message"
                handleClose={() => this.setState({openConfirmationWindow: false})}
                handleApply={this.handleUpdate.bind(this)}
            />

            <EditUserSettingsForm
                data={data}
                onHandleSubmit={this.handleSubmit.bind(this)}
                onHandleChange={this.handleChange.bind(this)}
                history={this.props.history}
                location={this.props.location}
                backUrl={this.backUrl}
            />
          </BsFormPanel>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    systemConfig: state.systemConfig.systemConfig
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSystemConfig: (systemId) => {
      dispatch(fetchSystemConfig(systemId));
    },
    updateClientPolicy: (systemId, data, redirectPath) => {
      dispatch(updateClientPolicy(systemId, data, redirectPath));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserSettings);
