import React, {useEffect} from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import BsFooter from "components/footer/BsFooter"
import BsHeader from "components/navigation/header/BsHeader"
import SideMenu from "components/navigation/side-menu/SideMenu";
import AutoRedirect from "components/navigation/redirect/AutoRedirect";
import BsPage from "components/page/BsPage";
import BsRouteHistory from "components/routing/BsRouteHistory";
import Dashboard from './pages/admin/dashboard/Dashboard'
import SignIn from './pages/login/sign-in/SignIn'
import ForgotPassword from "./pages/login/forgot-password/ForgotPassword";
import ResetPassword from "./pages/login/reset-password/ResetPassword";
import ActivationAccount from "./pages/login/activation-account/ActivationAccount";
import JoinSystem from "./pages/login/join-system/JoinSystem";
import ChangeProfile from "./pages/preference/profile/ChangeProfile";
import ChangePassword from "./pages/preference/password/ChangePassword";
import Users from "./pages/admin/users/Users";
import AddUser from "./pages/admin/users/add/AddUser";
import UserInfo from "./pages/admin/users/info/UserInfo";
import UserRentalInfo from "./pages/admin/users/info/tabs/rentals/info/UserRentalInfo";
import UserProblemIssueInfo from "./pages/admin/users/info/tabs/reported-problems/info/UserProblemIssueInfo";
import Rentals from "./pages/admin/rentals/Rentals";
import ActiveRentalInfo from "./pages/admin/rentals/info/history/RentalHistoryInfo";
import RentalHistoryInfo from "./pages/admin/rentals/info/active/ActiveRentalInfo";
import Bicycles from "./pages/admin/bicycles/Bicycles";
import AddBicycle from "./pages/admin/bicycles/add/AddBicycle";
import BicycleInfo from "./pages/admin/bicycles/info/BicycleInfo";
import EditBicycle from "./pages/admin/bicycles/edit/EditBicycle";
import BicycleRentalInfo from "./pages/admin/bicycles/info/tabs/rentals/info/BicycleRentalInfo";
import BicycleProblemIssueInfo from "./pages/admin/bicycles/info/tabs/problems/info/BicycleProblemIssueInfo";
import Locations from "./pages/admin/locations/Locations";
import LocationInfo from "./pages/admin/locations/info/LocationInfo";
import EditLocationInfo from "./pages/admin/locations/edit/EditLocationInfo";
import AddLocation from "./pages/admin/locations/add/AddLocation";
import LocationRentalInfo from "./pages/admin/locations/info/tabs/rentals/info/LocationRentalInfo";
import CustomerSupport from "./pages/admin/cusomer-support/CustomerSupport";
import SupportIssueInfo from "./pages/admin/cusomer-support/info/SupportIssueInfo";
import MaintenanceReports from "./pages/admin/reports/MaintenanceReports";
import MaintenanceBikeReportInfo from "./pages/admin/reports/info/MaintenanceBikeReportInfo";
import Settings from "./pages/admin/settings/Settings";
import Systems from "./pages/admin/systems/Systems";
import EditGeneralSettings from "./pages/admin/settings/tabs/general/edit/EditGeneralSettings";
import EditUserSettings from "./pages/admin/settings/tabs/user/edit/EditUserSettings";
import EditTosSettings from "./pages/admin/settings/tabs/tos/edit/EditTosSettings";
import EditGeneralHelpSettings from "./pages/admin/settings/tabs/help/edit/EditGeneralHelpSettings";
import EditBillingAddress from "./pages/admin/settings/tabs/subscription/edit/EditBillingAddress";
import AddSystemPaymentSource from "./pages/admin/settings/tabs/subscription/add-payment-source/AddSystemPaymentSource";
import AddSystemBankAccount from "./pages/admin/settings/tabs/subscription/add-bank-account/AddSystemBankAccount";
import IntegrateWithVelotooler from "./pages/admin/settings/tabs/fleet/integraion/integrate/IntegrateWithVelotooler";
import IntegrationSettings from "./pages/admin/settings/tabs/fleet/integraion/IntegrationSettings";
import OfferIntegrationSettings from "./pages/admin/settings/tabs/fleet/offer/OfferIntegrationSettings";
import FleetMaintenanceVisit from "./pages/admin/settings/tabs/fleet/visit/FleetMaintenanceVisit";
import FleetMaintenanceVisitError from "./pages/admin/settings/tabs/fleet/visit/FleetMaintenanceVisitError";
import AddSystem from "./pages/admin/systems/add/AddSystem";
import AddAdmin from "./pages/admin/settings/tabs/admins/add/AddAdmin";
import AdminInfo from "./pages/admin/settings/tabs/admins/info/AdminInfo";
import EditAdmin from "./pages/admin/settings/tabs/admins/info/edit/EditAdmin";
import Passwords from "./pages/admin/settings/tabs/user/passwords/Passwords";
import AddPassword from "./pages/admin/settings/tabs/user/passwords/add/AddPassword";
import PasswordInfo from "./pages/admin/settings/tabs/user/passwords/info/PasswordInfo";
import RentalRates from "./pages/admin/rental-rates/RentalRates";
import AddRentalRate from "./pages/admin/rental-rates/tabs/rates/add/AddRentalRate";
import RentalRateInfo from "./pages/admin/rental-rates/tabs/rates/info/RentalRateInfo";
import EditRentalRate from "./pages/admin/rental-rates/tabs/rates/info/tabs/info/edit/EditRentalRate";
import RentalPaymentInfo
  from "./pages/admin/rental-rates/tabs/rates/info/tabs/payments/info/RentalPaymentInfo";
import RentalRatePaymentInfo from "./pages/admin/rental-rates/tabs/payments/info/RentalRatePaymentInfo";
import AddRentalRateCoupon from "./pages/admin/rental-rates/tabs/coupons/add/AddRentalRateCoupon";
import RentalRateCouponInfo from "./pages/admin/rental-rates/tabs/coupons/info/RentalRateCouponInfo";
import EditRentalRateCoupon from "./pages/admin/rental-rates/tabs/coupons/edit/EditRentalRateCoupon";
import RentalRateCouponPaymentInfo
  from "./pages/admin/rental-rates/tabs/coupons/info/tabs/payments/info/RentalRateCouponPaymentInfo";
import DeleteAccount from "./pages/delete-account/DeleteAccount";
import NotFoundPage from "./pages/404/NotFoundPage";
import AllUsers from "./pages/global/users/AllUsers";
import ClientUserInfo from "./pages/global/users/info/ClientUserInfo";
import ClientUserRentalInfo from "./pages/global/users/info/tabs/rentals/info/ClientUserRentalInfo";
import ClientUserProblemIssueInfo
  from "./pages/global/users/info/tabs/reported-problems/info/ClientUserProblemIssueInfo";
import AllRentals from "./pages/global/rentals/AllRentals";
import RentalInfo from "./pages/global/rentals/info/RentalInfo";
import AllCustomerSupport from "./pages/global/cusomer-support/AllCustomerSupport";
import CustomerSupportIssueInfo from "./pages/global/cusomer-support/info/CustomerSupportIssueInfo";
import AllBicycles from "./pages/global/bicycles/AllBicycles";
import GeneralBicycleInfo from "./pages/global/bicycles/info/GeneralBicycleInfo";
import GeneralBicycleRentalInfo from "./pages/global/bicycles/info/tabs/rentals/info/GeneralBicycleRentalInfo";
import GeneralBicycleProblemIssueInfo
  from "./pages/global/bicycles/info/tabs/problems/info/GeneralBicycleProblemIssueInfo";
import BicycleReportInfo from "./pages/admin/bicycles/info/tabs/reports/info/BicycleReportInfo";
import GeneralBicycleReportInfo from "./pages/global/bicycles/info/tabs/reports/info/GeneralBicycleReportInfo";
import BicycleScanHistory from "./pages/global/bicycle-scan-history/BicycleScanHistory";
import BicycleScanByDay from "./pages/global/bicycle-scan-history/bicycle-scan-by-day/BicycleScanByDay";
import BackOfficeUsers from "./pages/global/admins/BackOfficeUsers";
import BackOfficeUserInfo from "./pages/global/admins/info/BackOfficeUserInfo";
import GlobalStatistics from "./pages/global/statistics/GlobalStatistics";
import Maintenance from "./pages/global/maintenance/Maintenance";
import AddMaintenancePlan from "./pages/global/maintenance/plans/add/AddMaintenancePlan";
import RenewMaintenancePlan from "./pages/global/maintenance/plans/renew/RenewMaintenancePlan";
import MaintenancePlanInfo from "./pages/global/maintenance/plans/info/MaintenancePlanInfo";
import MaintenanceVisit from "./pages/global/maintenance/plans/info/visit/MaintenanceVisit";
import MaintenanceVisitErrorMessage
  from "./pages/global/maintenance/plans/info/visit/error/MaintenanceVisitErrorMessage";
import MaintenanceVisitInfo from "./pages/global/maintenance/visits/info/MaintenanceVisitInfo";
import MaintenanceVisitErrorInfo from "./pages/global/maintenance/visits/info/error/MaintenanceVisitErrorInfo";
import AllMaintenanceReports from "./pages/global/reports/AllMaintenanceReports";
import MaintenanceReportInfo from "./pages/global/reports/info/MaintenanceReportInfo";
import AllLabels from "./pages/global/labels/AllLabels";
import AddLabel from "./pages/global/labels/add/AddLabel";
import EditLabel from "./pages/global/labels/edit/EditLabel";
import LabelInfo from "./pages/global/labels/info/LabelInfo";
import AddLabelSystem from "./pages/global/labels/info/tabs/systems/add/AddLabelSystem";
import LabelSystemInfo from "./pages/global/labels/info/tabs/systems/info/LabelSystemInfo";
import AddLabelAdmin from "./pages/global/labels/info/tabs/admins/add/AddLabelAdmin";
import LabelAdminInfo from "./pages/global/labels/info/tabs/admins/info/LabelAdminInfo";
import Subscriptions from "./pages/global/subscriptions/Subscriptions";
import SubscriptionPlanInfo from "./pages/global/subscriptions/plans/info/SubscriptionPlanInfo";
import AddSubscriptionPlan from "./pages/global/subscriptions/plans/add/AddSubscriptionPlan";
import EditSubscriptionPlanInfo from "./pages/global/subscriptions/plans/edit/EditSubscriptionPlanInfo";
import SubscriptionSystemInfo from "./pages/global/subscriptions/systems/info/SubscriptionSystemInfo";
import EditSubscriptionSystem from "./pages/global/subscriptions/systems/edit/EditSubscriptionSystem";
import LabelStatistics from "./pages/labels/statistics/LabelStatistics";
import LabelRentals from "./pages/labels/rentals/LabelRentals";
import LabelRentalInfo from "./pages/labels/rentals/info/LabelRentalInfo";
import LabelCustomerSupport from "./pages/labels/customer-support/LabelCustomerSupport";
import LabelCustomerSupportIssueInfo from "./pages/labels/customer-support/info/LabelCustomerSupportIssueInfo";
import LabelBicycles from "./pages/labels/bicycles/LabelBicycles";
import LabelGeneralBicycleInfo from "./pages/labels/bicycles/info/LabelGeneralBicycleInfo";
import LabelGeneralBicycleRentalInfo
  from "./pages/labels/bicycles/info/tabs/rentals/info/LabelGeneralBicycleRentalInfo";
import LabelGeneralBicycleProblemIssueInfo
  from "./pages/labels/bicycles/info/tabs/problems/info/LabelGeneralBicycleProblemIssueInfo";
import LabelUsers from "./pages/labels/users/LabelUsers";
import LabelClientUserInfo from "./pages/labels/users/info/LabelClientUserInfo";
import LabelClientUserRentalInfo from "./pages/labels/users/info/tabs/rentals/info/LabelClientUserRentalInfo";
import LabelClientUserProblemIssueInfo
  from "./pages/labels/users/info/tabs/reported-problems/info/LabelClientUserProblemIssueInfo";
import {clearNotifications, fetchAppVersion} from "core/actions/app-actions";
import {loadProfile} from "core/actions/profile-actions";
import {loadSystem} from "core/actions/system-actions";

import {connect} from "react-redux";
import {useSnackbar} from 'notistack';
import {useTranslation} from "react-i18next";
import './App.scss';

function AppRouting(props) {
  const {t} = useTranslation();

  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (props.notifications.length > 0) {
      props.notifications.forEach(notification => {
        showNotification(t(notification.message), notification.variant);
      });
      props.clearNotifications();
    }

    if (props.needToLoadProfile) {
      props.loadProfile(props.needToLoadDefaultSystem);
    }

    if (props.refreshSystem) {
      props.loadSystem(props.systemId)
    }

    if (props.needToLoadAppVersion) {
      props.loadAppVersion()
    }
  });

  function showNotification(message, variant) {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      autoHideDuration: 3000
    })
  }

  function getSideMenuType(route) {
    const currentPath = route.location.pathname;
    if (currentPath.startsWith('/global/')) {
      return 'global';
    } else if (currentPath.startsWith('/label/')) {
      return 'label';
    } else if (props.showSideMenu) {
      return 'system';
    }

    return null;
  }

  function sideMenuClass(route) {
    return getSideMenuType(route) ? 'fade-with-side-menu' : '';
  }

  return (
      <Router>
        <AutoRedirect/>
        <Route render={(route) => (
            <div className={sideMenuClass(route)}>
              <BsRouteHistory route={route}/>
              <BsHeader history={route.history}/>

              <div className="app">
                <SideMenu route={route} type={getSideMenuType(route)}/>

                <div className="main-app">
                  <div className="content">
                    <BsPage>
                      <Switch location={route.location}>
                        <Route exact path="/" component={SignIn}/>
                        <Route path="/sign-in" component={SignIn}/>
                        <Route path="/forgot-password" component={ForgotPassword}/>
                        <Route path="/reset-password/:token" component={ResetPassword}/>
                        <Route path="/activation-account/:token" component={ActivationAccount}/>
                        <Route path="/join-system/:token" component={JoinSystem}/>

                        <Route path="/change-profile" component={ChangeProfile}/>
                        <Route path="/change-password" component={ChangePassword}/>

                        <Route path="/systems" component={Systems}/>
                        <Route path="/create-system" component={AddSystem}/>
                        <Route exact path="/system/:systemId" component={Dashboard}/>

                        <Route path="/system/:systemId/dashboard" component={Dashboard}/>

                        <Route path="/system/:systemId/rentals" component={Rentals}/>
                        <Route path="/system/:systemId/rental/active/:rentalId/info" component={ActiveRentalInfo}/>
                        <Route path="/system/:systemId/rental/history/:rentalId/info" component={RentalHistoryInfo}/>

                        <Route path="/system/:systemId/users" component={Users}/>
                        <Route path="/system/:systemId/user/:userId" component={UserInfo}/>
                        <Route path="/system/:systemId/user/:userId/:tab" component={UserInfo}/>
                        <Route path="/system/:systemId/user-info/:userId/rental/:rentalId/info"
                               component={UserRentalInfo}/>
                        <Route path="/system/:systemId/create-users" component={AddUser}/>
                        <Route path="/system/:systemId/user-info/:userId/reported-problem/:issueId"
                               component={UserProblemIssueInfo}/>

                        <Route path="/system/:systemId/bicycles" component={Bicycles}/>
                        <Route path="/system/:systemId/create-bicycle" component={AddBicycle}/>
                        <Route path="/system/:systemId/bicycle/:bicycleId" component={BicycleInfo}/>
                        <Route path="/system/:systemId/bicycle/:bicycleId/:tab" component={BicycleInfo}/>
                        <Route path="/system/:systemId/edit-bicycle/:bicycleId" component={EditBicycle}/>
                        <Route path="/system/:systemId/bicycle-info/:bicycleId/rental/:rentalId/info"
                               component={BicycleRentalInfo}/>
                        <Route path="/system/:systemId/bicycle-info/:bicycleId/problem/:issueId"
                               component={BicycleProblemIssueInfo}/>
                        <Route path="/system/:systemId/bicycle-info/:bicycleId/report/:reportId"
                               component={BicycleReportInfo}/>

                        <Route path="/system/:systemId/locations" component={Locations}/>
                        <Route path="/system/:systemId/location/:locationId" component={LocationInfo}/>
                        <Route path="/system/:systemId/location/:locationId/:tab" component={LocationInfo}/>
                        <Route path="/system/:systemId/create-location" component={AddLocation}/>
                        <Route path="/system/:systemId/edit-location/:locationId" component={EditLocationInfo}/>
                        <Route path="/system/:systemId/location-info/:locationId/rental/:rentalId/info"
                               component={LocationRentalInfo}/>

                        <Route path="/system/:systemId/customer-support" component={CustomerSupport}/>
                        <Route path="/system/:systemId/support/issue/:issueId" component={SupportIssueInfo}/>

                        <Route path="/system/:systemId/maintenance-reports" component={MaintenanceReports}/>
                        <Route path="/system/:systemId/maintenance-report/:reportId"
                               component={MaintenanceBikeReportInfo}/>

                        <Route path="/system/:systemId/settings" component={Settings}/>
                        <Route path="/system/:systemId/edit-settings/general" component={EditGeneralSettings}/>
                        <Route path="/system/:systemId/edit-settings/user" component={EditUserSettings}/>
                        <Route path="/system/:systemId/edit-settings/tos" component={EditTosSettings}/>
                        <Route path="/system/:systemId/edit-settings/general-help" component={EditGeneralHelpSettings}/>
                        <Route path="/system/:systemId/edit-settings/billing-address" component={EditBillingAddress}/>
                        <Route path="/system/:systemId/payment-source/add" component={AddSystemPaymentSource}/>
                        <Route path="/system/:systemId/bank-account/add" component={AddSystemBankAccount}/>
                        <Route path="/system/:systemId/edit-settings/integration/show"
                               component={IntegrationSettings}/>
                        <Route path="/system/:systemId/edit-settings/integration/edit"
                               component={IntegrateWithVelotooler}/>
                        <Route path="/system/:systemId/edit-settings/offer-integration"
                               component={OfferIntegrationSettings}/>
                        <Route path="/system/:systemId/edit-settings/visit/:visitId/info"
                               component={FleetMaintenanceVisit}/>
                        <Route path="/system/:systemId/edit-settings/visit/:visitId/error"
                               component={FleetMaintenanceVisitError}/>
                        <Route path="/system/:systemId/settings/:setting" component={Settings}/>
                        <Route path="/system/:systemId/admins/add" component={AddAdmin}/>
                        <Route path="/system/:systemId/admins/:userId/info" component={AdminInfo}/>
                        <Route path="/system/:systemId/admins/:userId/edit" component={EditAdmin}/>

                        <Route path="/system/:systemId/edit-settings/passwords" component={Passwords}/>
                        <Route path="/system/:systemId/edit-settings/create-password" component={AddPassword}/>
                        <Route path="/system/:systemId/edit-settings/password/:passwordId" component={PasswordInfo}/>
                        <Route path="/system/:systemId/edit-settings/password/:passwordId/:tab" component={PasswordInfo}/>

                        <Route path="/system/:systemId/rental-rates" component={RentalRates}/>
                        <Route path="/system/:systemId/create-rental-rate" component={AddRentalRate}/>
                        <Route path="/system/:systemId/rental-rate/:rateId" component={RentalRateInfo}/>
                        <Route path="/system/:systemId/rental-rate/:rateId/:tab" component={RentalRateInfo}/>
                        <Route path="/system/:systemId/edit-rental-rate/:rateId" component={EditRentalRate}/>
                        <Route path="/system/:systemId/rental-rate-payment/:rateId/:paymentId" component={RentalPaymentInfo}/>
                        <Route path="/system/:systemId/rental-rate-payment-info/:paymentId" component={RentalRatePaymentInfo}/>
                        <Route path="/system/:systemId/create-rental-rate-coupon" component={AddRentalRateCoupon}/>
                        <Route path="/system/:systemId/rental-rate-coupon/:couponId" component={RentalRateCouponInfo}/>
                        <Route path="/system/:systemId/update-rental-rate-coupon-info/:couponId" component={EditRentalRateCoupon}/>
                        <Route path="/system/:systemId/rental-rate-coupon-payment-info/:couponId/:paymentId" component={RentalRateCouponPaymentInfo}/>

                        <Route path="/global/statistics" component={GlobalStatistics}/>

                        <Route path="/global/users" component={AllUsers}/>
                        <Route path="/global/user/:userId" component={ClientUserInfo}/>
                        <Route path="/global/user/:userId/:tab" component={ClientUserInfo}/>
                        <Route path="/global/user-info/:userId/rental/:rentalId" component={ClientUserRentalInfo}/>
                        <Route path="/global/user-info/:userId/reported-problem/:issueId"
                               component={ClientUserProblemIssueInfo}/>

                        <Route path="/global/rentals" component={AllRentals}/>
                        <Route path="/global/rental/:rentalId" component={RentalInfo}/>

                        <Route path="/global/reported-problems" component={AllCustomerSupport}/>
                        <Route path="/global/reported-problem-info/:issueId" component={CustomerSupportIssueInfo}/>

                        <Route path="/global/bicycles" component={AllBicycles}/>
                        <Route path="/global/bicycle/:bicycleId" component={GeneralBicycleInfo}/>
                        <Route path="/global/bicycle/:bicycleId/:tab" component={GeneralBicycleInfo}/>
                        <Route path="/global/bicycle-info/:bicycleId/rental/:rentalId"
                               component={GeneralBicycleRentalInfo}/>
                        <Route path="/global/bicycle-info/:bicycleId/reported-problem/:issueId"
                               component={GeneralBicycleProblemIssueInfo}/>
                        <Route path="/global/bicycle-info/:bicycleId/report/:reportId"
                               component={GeneralBicycleReportInfo}/>

                        <Route path="/global/scan-bicycles-history" component={BicycleScanHistory}/>
                        <Route path="/global/scan-bicycles/:location/:user/:day" component={BicycleScanByDay}/>

                        <Route path="/global/bo-users" component={BackOfficeUsers}/>
                        <Route path="/global/bo-user-info/:userId" component={BackOfficeUserInfo}/>

                        <Route path="/global/maintenance" component={Maintenance}/>
                        <Route path="/global/add-maintenance-plan" component={AddMaintenancePlan}/>
                        <Route path="/global/maintenance-plan/:planId/info" component={MaintenancePlanInfo}/>
                        <Route path="/global/maintenance-plan/:planId/renew" component={RenewMaintenancePlan}/>
                        <Route path="/global/maintenance-plan/:planId/visit/:number/info" component={MaintenanceVisit}/>
                        <Route path="/global/maintenance-plan/:planId/visit/:number/error"
                               component={MaintenanceVisitErrorMessage}/>
                        <Route path="/global/maintenance-visit/:visitId/info" component={MaintenanceVisitInfo}/>
                        <Route path="/global/maintenance-visit/:visitId/error" component={MaintenanceVisitErrorInfo}/>

                        <Route path="/global/maintenance-reports" component={AllMaintenanceReports}/>
                        <Route path="/global/maintenance-report/:reportId/info" component={MaintenanceReportInfo}/>

                        <Route path="/global/subscriptions" component={Subscriptions}/>
                        <Route path="/global/subscription-plan/:planId/info" component={SubscriptionPlanInfo}/>
                        <Route path="/global/subscription-plan/:planId/edit" component={EditSubscriptionPlanInfo}/>
                        <Route path="/global/add-subscription-plan" component={AddSubscriptionPlan}/>
                        <Route path="/global/system-subscription/:systemId/info" component={SubscriptionSystemInfo}/>
                        <Route path="/global/system-subscription/:systemId/edit" component={EditSubscriptionSystem}/>

                        <Route path="/global/labels" component={AllLabels}/>
                        <Route path="/global/add-label" component={AddLabel}/>
                        <Route path="/global/edit-label/:labelId" component={EditLabel}/>
                        <Route path="/global/label/:labelId" component={LabelInfo}/>
                        <Route path="/global/label/:labelId/:tab" component={LabelInfo}/>
                        <Route path="/global/update-label-data/:labelId/add-system" component={AddLabelSystem}/>
                        <Route path="/global/update-label-data/:labelId/system/:systemId" component={LabelSystemInfo}/>
                        <Route path="/global/update-label-data/:labelId/add-admin" component={AddLabelAdmin}/>
                        <Route path="/global/update-label-data/:labelId/admin/:userId" component={LabelAdminInfo}/>

                        <Route path="/label/:labelId/statistics" component={LabelStatistics}/>

                        <Route path="/label/:labelId/users" component={LabelUsers}/>
                        <Route path="/label/:labelId/user/:userId" component={LabelClientUserInfo}/>
                        <Route path="/label/:labelId/user/:userId/:tab" component={LabelClientUserInfo}/>
                        <Route path="/label/:labelId/user-info/:userId/rental/:rentalId"
                               component={LabelClientUserRentalInfo}/>
                        <Route path="/label/:labelId/user-info/:userId/reported-problem/:issueId"
                               component={LabelClientUserProblemIssueInfo}/>

                        <Route path="/label/:labelId/rentals" component={LabelRentals}/>
                        <Route path="/label/:labelId/rental/:rentalId" component={LabelRentalInfo}/>

                        <Route path="/label/:labelId/reported-problems" component={LabelCustomerSupport}/>
                        <Route path="/label/:labelId/reported-problem-info/:issueId"
                               component={LabelCustomerSupportIssueInfo}/>

                        <Route path="/label/:labelId/bicycles" component={LabelBicycles}/>
                        <Route path="/label/:labelId/bicycle/:bicycleId" component={LabelGeneralBicycleInfo}/>
                        <Route path="/label/:labelId/bicycle/:bicycleId/:tab" component={LabelGeneralBicycleInfo}/>
                        <Route path="/label/:labelId/bicycle-info/:bicycleId/rental/:rentalId"
                               component={LabelGeneralBicycleRentalInfo}/>
                        <Route path="/label/:labelId/bicycle-info/:bicycleId/reported-problem/:issueId"
                               component={LabelGeneralBicycleProblemIssueInfo}/>

                        <Route path="/delete-account" component={DeleteAccount}/>

                        <Route component={NotFoundPage}/>
                      </Switch>
                    </BsPage>
                  </div>

                  <BsFooter/>
                </div>
              </div>
            </div>
        )}/>
      </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    appVersion: state.app.appVersion,
    needToLoadAppVersion: state.app.needToLoadAppVersion,
    notifications: state.app.notifications,
    needToLoadProfile: !state.profile.profileWasLoaded && state.security.isLoggedIn && !state.profile.profileIsLoading,
    systemId: state.system.systemId,
    refreshSystem: !state.system.systemIsLoading && state.system.systemId
        && (!state.system.currentSystem || (state.system.systemId + '' !== state.system.currentSystem.id + '')),
    showSideMenu: state.security.isLoggedIn && state.system.systemId && state.system.currentSystem,
    needToLoadDefaultSystem: state.system.needToLoadDefaultSystem
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearNotifications: () => {
      dispatch(clearNotifications());
    },
    loadProfile: (needToLoadDefaultSystem) => {
      dispatch(loadProfile(needToLoadDefaultSystem));
    },
    loadSystem: (id) => {
      dispatch(loadSystem(id));
    },
    loadAppVersion: () => {
      dispatch(fetchAppVersion());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouting);
